import type { Response } from '~/types/api/v1'

import type { TemplateData } from '../useFetchTemplate'
import type { UserTemplateData } from '../user-template/useFetchUserTemplate'

import { useFetchWithDefaults } from '../../useFetchWithDefaults'

export const responseDataTypeName = 'recentTemplate'

export interface ParametersGetRecent {
  limit?: number
}

export const useFetchRecentTemplate = () => {
  const { get } = useFetchWithDefaults()

  const getRecent = ({
    workspaceId,
    params,
  }: {
    workspaceId: string
    params?: ParametersGetRecent
  }) =>
    get<{ included: Array<TemplateData | UserTemplateData> }>({
      url: `/me/templates/recent`,
      workspaceId,
      fetchOptions: { params },
    })
    // The endpoint uses `$defaultIncludes` for the relationships, so we are getting this behavior by default:
    // { include: 'template,userTemplate' }
      .then(({ included }) => ({ data: included }) as Response<Array<TemplateData | UserTemplateData>>)

  return {
    getRecent,
  }
}
