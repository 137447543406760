import LineInfoCircle from '@docue/docue-ui-v2/icons/LineInfoCircle.vue'
import LineAlertCircle from '@docue/docue-ui-v2/icons/LineAlertCircle.vue'
import LineCheckCircleBroken from '@docue/docue-ui-v2/icons/LineCheckCircleBroken.vue'

import type { Toast } from '~/types/ui'

type ToastWithoutId = Omit<Toast, 'id'>

interface ToastWithoutContent extends Omit<ToastWithoutId, 'content'> {}
interface PartialToast extends Partial<ToastWithoutId> {
  content: string
}

export default () => {
  const toasts = useState<Array<Toast>>('toasts', () => [])

  const add = (toast: ToastWithoutId) => {
    const toastId = crypto.randomUUID()
    toasts.value.push({ id: toastId, ...toast })

    return toastId
  }

  const toastWithDefaults = (defaults: ToastWithoutContent) =>
    (content: string | PartialToast) => {
      if (typeof content === 'string') {
        return add({
          content,
          ...defaults,
        })
      }

      return add({
        ...defaults,
        ...content,
      })
    }

  const remove = (toastId: string) => {
    toasts.value = toasts.value.filter(t => t.id !== toastId)
  }

  return {
    add,
    remove,
    success: toastWithDefaults({ variant: 'green', icon: markRaw(LineCheckCircleBroken) }),
    info: toastWithDefaults({ variant: 'blue', icon: markRaw(LineInfoCircle) }),
    warning: toastWithDefaults({ variant: 'yellow', icon: markRaw(LineAlertCircle) }),
    danger: toastWithDefaults({ variant: 'red', icon: markRaw(LineAlertCircle) }),
  }
}
