import type { Model, Response } from '~/types/api/v1'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export type TemplateCategoryData = Model<{
  name: string
  categoryId?: string // TODO: remove, DEV-6055
  slug: string // TODO: remove, DEV-6055
}>

export const responseDataTypeName: TemplateCategoryData['type'] = 'category'

export const useFetchTemplateCategory = () => {
  const url = '/categories'

  const { get } = useFetchWithDefaults()

  const getById = ({ id, workspaceId }: { id: string, workspaceId?: string }) =>
    get<Response<TemplateCategoryData>>({ url: `${url}/${id}`, workspaceId })

  const getAll = ({ workspaceId }: { workspaceId?: string }) =>
    get<Response<TemplateCategoryData[]>>({ url, workspaceId })
    // TODO: remove, DEV-6055 update to v2 categories endpoint
      .then((response) => {
        // We used to have 2 "root" categories for client and company categories.
        // In the application, we have shown the categories under the "company" categories.
        // After DEV-6055, no category will have these parents assigned.
        const parentCategoryCount = response.data.filter(c => !c.attributes.categoryId).length
        // Old category listing, fallback to categories under "company" parent category
        if (parentCategoryCount <= 2) {
          const parentCategory = response.data.find(c =>
            ['yrityssopimukset', 'company-documents', 'dokument-for-foretag'].includes(c.attributes.slug))
          response.data = response.data.filter(c => c.attributes.categoryId === parentCategory?.id)
        }

        return response
      })

  return {
    getById,
    getAll,
  }
}
