import type { WorkspaceIdentifier } from '../../types'

import { useFetchWithDefaults } from '../../useFetchWithDefaults'

export interface PreviewSigneeInformation {
  firstName: string
  lastName: string
  email: string
  companyTitle?: string
  title?: string
  phoneNumber?: string
  countryCode?: string
}

export const useFetchContractSignaturePreview = () => {
  const url = '/me/contracts/simple-preview'

  const { post } = useFetchWithDefaults()

  const createPreview = ({
    workspaceId,
    signees,
    language,
  }: WorkspaceIdentifier & {
    signees: PreviewSigneeInformation[]
    language: App.Internals.Enums.Language
  }) => post<Blob>({
    url,
    workspaceId,
    fetchOptions: {
      body: {
        data: {
          preview: true,
          language,
          parties: signees,
        },
      },
    },
  })

  return { createPreview }
}
