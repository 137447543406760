import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { type ParametersGetAll, type ParametersGetEntity, responseDataTypeName, useFetchContact } from './useFetchContact'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    params?: MaybeRef<ParametersGetAll>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(params ? [params] : []),
    ],
  }),
  companies: ({
    workspaceId,
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    params?: MaybeRef<ParametersGetEntity<'persons'>>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(params ? [params] : []),
    ],
  }),
})

const all = (
  {
    params,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAll> | undefined
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAll } = useFetchContact()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({
      workspaceId: activeWorkspaceId as MaybeRef<string>, // DEV-5806 remove casting */
      params,
    }),
    queryFn: () => getAll({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) ?? '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const companies = (
  {
    params,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetEntity<'persons'>>
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getCompanies } = useFetchContact()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.companies({
      workspaceId: activeWorkspaceId as MaybeRef<string>, // DEV-5806 remove casting
      params,
    }),
    queryFn: () => getCompanies({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) ?? '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryContact = {
  all,
  companies,
}
