<script lang="ts">
const pathToSignDocument = '/2/sign'
const pathToCreateDocument = '/2/templates'
</script>
<script setup lang="ts">
import LinePlus from '@docue/docue-ui-v2/icons/LinePlus.vue'
import LineClock from '@docue/docue-ui-v2/icons/LineClock.vue'
import LineHome01 from '@docue/docue-ui-v2/icons/LineHome01.vue'
import LineFolder from '@docue/docue-ui-v2/icons/LineFolder.vue'
import LineScales01 from '@docue/docue-ui-v2/icons/LineScales01.vue'
import SignatureAlt from '@docue/docue-ui-v2/icons/SignatureAlt.vue'
import LineInfoCircle from '@docue/docue-ui-v2/icons/LineInfoCircle.vue'
import LineBookOpen01 from '@docue/docue-ui-v2/icons/LineBookOpen01.vue'
import LineChevronDown from '@docue/docue-ui-v2/icons/LineChevronDown.vue'
import LineLayerSingle from '@docue/docue-ui-v2/icons/LineLayerSingle.vue'
import LineChevronRight from '@docue/docue-ui-v2/icons/LineChevronRight.vue'
import LineLayersThree01 from '@docue/docue-ui-v2/icons/LineLayersThree01.vue'
import LineArrowNarrowUpRight from '@docue/docue-ui-v2/icons/LineArrowNarrowUpRight.vue'

const { workspace, isCurrentUserAdmin } = useAuth()
const { data: config } = useQueryConfig.current()
const { t } = useI18n()

const hasFeatureInformationServices = computed(
  () => !!config.value?.feature_enabled_information_services,
)

const hasNewArchive = computed(() => !!workspace.value?.attributes.root_folder_id)
const archivePath = computed(() => hasNewArchive.value ? '/2/documents' : '/documents')

const route = useRoute()

const { loadAllViews, allViews: views } = useViews()
const viewsExpanded = useLocalStorage('app-navigation-views-expanded', () => false)




const isTemplateDetailRoute = computed(() => route.path.startsWith('/2/template/'))

onMounted(() => loadAllViews())

watch(() => route.params, (params) => {
  if ('viewId' in params && params.viewId)
    viewsExpanded.value = true
}, { immediate: true })
</script>

<template>
  <nav class="flex flex-col gap-4">
    <DTNavList>
      <DTNavItem
        v-if="hasNewArchive"
        to="/2/home"
        :active="route.path.startsWith('/2/home')"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <LineHome01 class="size-full stroke-2" />
        </DTNavItemIcon>
        {{ t('navigation.home') }}
      </DTNavItem>
      <DTNavItem
        :to="pathToCreateDocument"
        :active="route.path.startsWith(pathToCreateDocument) || isTemplateDetailRoute"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <LinePlus class="size-full stroke-2" />
        </DTNavItemIcon>
        {{ t('navigation.templates') }}
      </DTNavItem>
      <DTNavItem
        :to="pathToSignDocument"
        :active="route.path.startsWith(pathToSignDocument)"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <SignatureAlt class="size-full" />
        </DTNavItemIcon>
        {{ t('navigation.sign') }}
      </DTNavItem>
    </DTNavList>

    <DTNavList>
      <DTNavListHeading class="!mb-0 !py-1 pl-1.5 !text-xs">
        {{ t('navigation.heading.workspace-documents') }}
      </DTNavListHeading>
      <DTNavItem
        :to="archivePath"
        :active="route.path === '/2/documents' || route.path.startsWith('/2/documents/f/')"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <LineFolder class="size-full" />
        </DTNavItemIcon>
        {{ hasNewArchive ? t('documents.root-title') : t('navigation.all-documents') }}
      </DTNavItem>

      <template v-if="hasNewArchive">
        <DTNavItem
          to="/2/documents/v/recent"
          :active="route.path.startsWith('/2/documents/v/recent')"
          variant="light-blue"
          class="whitespace-nowrap"
          component-class="!text-[0.8125rem]"
        >
          <DTNavItemIcon>
            <LineClock class="size-full" />
          </DTNavItemIcon>
          {{ t('navigation.documents.recent') }}
        </DTNavItem>
        <li
          class="flex list-none flex-col gap-1 whitespace-nowrap"
        >
          <div
            class="flex items-center gap-0.5 rounded-lg pr-1 hover:bg-blue-25"
          >
            <button
              :aria-expanded="viewsExpanded"
              aria-controls="documents-view-list"
              class="
                  flex
                  grow
                  cursor-default
                  items-center
                  gap-3
                  rounded-lg
                  px-3
                  py-1.5
                  text-sm
                  text-gray-700
                  "
              @click="viewsExpanded = !viewsExpanded"
            >
              <DTNavItemIcon>
                <LineLayersThree01 class="size-full" />
              </DTNavItemIcon>
              <span class="flex items-center gap-1">
                <span class="text-[0.8125rem]">{{ t('navigation.views.title') }}</span>
                <span
                  class="flex size-4 items-center justify-center"
                >
                  <LineChevronDown
                    v-if="viewsExpanded"
                    class="size-3 [&>path]:stroke-[2.5]"
                  />
                  <LineChevronRight
                    v-else
                    class="size-3 [&>path]:stroke-[2.5]"
                  />
                </span>
              </span>
            </button>
            <NuxtLink
              v-if="isCurrentUserAdmin"
              v-dt-tooltip.dark="t('navigation.views.create')"
              to="/2/documents/v/new"
              class="
                  flex
                  size-6
                  items-center
                  justify-center
                  rounded-lg
                  text-gray-400
                  hover:bg-blue-50
                  hover:text-blue-800
                  "
            >
              <LinePlus class="size-4" />
              <span class="sr-only">{{ t('navigation.views.create') }}</span>
            </NuxtLink>
          </div>
          <DTNavList
            v-show="viewsExpanded"
            id="documents-view-list"
            :aria-hidden="!viewsExpanded"
            class="ml-4"
          >
            <DTLoaderDots v-if="!views" />
            <span
              v-else-if="!views.length"
              class="pl-6 text-xs text-gray-500"
            >{{ t('navigation.views.no-views') }}</span>
            <template v-else>
              <DTNavItem
                v-for="view in views"
                :key="view.id"
                :to="`/2/documents/v/${view.id}`"
                :active="route?.params?.viewId === view.id"
                variant="light-blue"
                :title="view.title"
                component-class="w-full"
              >
                <DTNavItemIcon class="shrink-0">
                  <LineLayerSingle class="size-full" />
                </DTNavItemIcon>

                <div class="truncate whitespace-nowrap text-[0.8125rem]">
                  {{ view.title }}
                </div>
              </DTNavItem>
            </template>
          </DTNavList>
        </li>
      </template>

      <DTNavItem
        v-else
        :to="{ path: '/documents/finalized', external: true }"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <i class="size-2 rounded-full bg-yellow-400" />
        </DTNavItemIcon>
        {{ t('navigation.documents.finalized') }}
      </DTNavItem>
    </DTNavList>

    <DTNavList>
      <DTNavListHeading class="!mb-0 !py-1 pl-1.5 text-xs">
        {{ t('navigation.heading.tools') }}
      </DTNavListHeading>
      <DTNavItem
        to="/contacts"
        :active="route.path.startsWith('/contacts')"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <LineBookOpen01 class="size-full" />
        </DTNavItemIcon>
        {{ t('navigation.contacts') }}
      </DTNavItem>
      <DTNavItem
        v-if="hasFeatureInformationServices"
        to="/information-services"
        :active="route.path.startsWith('/information-services')"
        variant="light-blue"
        class="whitespace-nowrap"
        component-class="!text-[0.8125rem]"
      >
        <DTNavItemIcon>
          <LineInfoCircle class="size-full stroke-2" />
        </DTNavItemIcon>
        {{ t('navigation.information-services') }}
      </DTNavItem>
      <!-- TODO: remove click-handlers when DTNavItem supports target property DEV-6144 -->
      <DTNavItem
        v-if="config?.link_legal_articles"
        :to="config.link_legal_articles"
        class="whitespace-nowrap"
        variant="light-blue"
        component-class="!text-[0.8125rem]"
        @click.prevent.stop="navigateTo(
          config.link_legal_articles, { external: true, open: { target: '_blank' } },
        )"
      >
        <DTNavItemIcon>
          <LineScales01 class="size-full stroke-2" />
        </DTNavItemIcon>
        <span class="grow text-left">{{ t('navigation.legal-hub') }}</span>
        <DTNavItemIcon>
          <LineArrowNarrowUpRight class="size-full stroke-2 text-gray-400" />
        </DTNavItemIcon>
      </DTNavItem>
    </DTNavList>
  </nav>
</template>
