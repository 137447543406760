import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { type IncludableField, type ParametersGetAll, responseDataTypeName, useFetchWorkspace } from './useFetchWorkspace'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ params }: { params?: MaybeRef<ParametersGetAll> }) =>
    ({ queryKey: ['' /* typescript wants a fixed entry here */, params] }),
  byId: ({ workspaceId, fieldsToInclude }: { workspaceId: MaybeRef<string>, fieldsToInclude?: IncludableField[] }) =>
    ({ queryKey: [workspaceId, fieldsToInclude?.sort()] }),
})

const all = ({
  params,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetAll>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchWorkspace()

  return useQuery({
    ...queryKeys.all({ params }),
    queryFn: () => getAll({ params: unref(params) }),
    ...useQueryOptions,
  })
}

const byId = ({
  workspaceId,
  fieldsToInclude,
  useQueryOptions = {},
}: {
  workspaceId: MaybeRef<string>
  fieldsToInclude?: IncludableField[]
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getById } = useFetchWorkspace()

  return useQuery({
    ...queryKeys.byId({
      workspaceId,
      fieldsToInclude,
    }),
    queryFn: () => getById({
      workspaceId: unref(workspaceId),
      fieldsToInclude: fieldsToInclude ?? [],
    }),
    enabled: () => !!unref(workspaceId),
    ...useQueryOptions,
  })
}

export const useQueryWorkspace = {
  all,
  byId,
}
