import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchRating } from './useFetchRating'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  getRating: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const getRating = ({
  useQueryOptions,
}: {
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getRating } = useFetchRating()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getRating({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getRating({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryRating = {
  getRating,
}
