<script lang="ts" setup>
import LineCheck from '@docue/docue-ui-v2/icons/LineCheck.vue'
import DTDropdownMenu from '@docue/docue-ui-v2/ui-components/DTDropdown/DTDropdownMenu.vue'

import { queryKeys as configQueryKeys } from '~/composables/api/config/useQueryConfig'

interface LocaleOption {
  code: App.Internals.Enums.Language
  text: string
}

defineProps<{
  menuProps?: InstanceType<typeof DTDropdownMenu>['$props']
}>()

const { user } = useAuth()
const { language } = useLanguage()
const { sortIntl } = useIntl()
const queryClient = useQueryClient()

const defaultLocales: LocaleOption[] = [
  {
    code: 'de',
    text: 'Deutsch',
  },
  {
    code: 'en',
    text: 'English',
  },
  {
    code: 'fi',
    text: 'Suomi',
  },
  {
    code: 'sv',
    text: 'Svenska',
  },
]

const { enabled: isPolishLanguageEnabled } = useFeatureEnabled('polish-language')

const locales = computed<LocaleOption[]>(() => isPolishLanguageEnabled.value ? [...defaultLocales, { code: 'pl', text: 'Polski' }] : defaultLocales)

const sortedLocales = computed(() => sortIntl(locales.value, ({ text }) => text))

const setLanguage = async (code: App.Internals.Enums.Language) => {
  language.value = code

  if (user.value) {
    const res = await useApi().updateUser({
      id: user.value.id,
      type: user.value.type,
      attributes: {
        language: language.value,
      },
    })

    user.value = res.data
    queryClient.invalidateQueries({ queryKey: configQueryKeys._def })
  }
}

const currentLocale = computed(() =>
  locales.value.find(({ code }) => code === language.value),
)
</script>

<template>
  <DTDropdown>
    <slot
      name="button"
      :text="currentLocale?.text"
      :country-code="currentLocale?.code"
    />
    <DTDropdownMenu v-bind="menuProps">
      <DTDropdownMenuItem
        v-for="locale in sortedLocales"
        :key="locale.code"
        class="flex items-center justify-between gap-5 text-sm"
        @click="setLanguage(locale.code)"
      >
        {{ locale.text }}
        <LineCheck
          v-if="currentLocale && locale.code === currentLocale.code"
          class="w-4 stroke-2"
        />
      </DTDropdownMenuItem>
    </DTDropdownMenu>
  </DTDropdown>
</template>
