export default (feature: string) => {
  const { $posthog } = useNuxtApp()

  const isFeatureEnabled = ref(false)
  $posthog.onFeatureFlags(() => {
    isFeatureEnabled.value = !!$posthog.isFeatureEnabled(feature)
  })

  return {
    enabled: isFeatureEnabled,
  }
}
