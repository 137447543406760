import type { Model, Relationship, Response } from '~/types/api/v1'

import { populateRelationships } from '~/utils/api/v1'

import type { WorkspaceIdentifier } from '../../types'
import type { TemplateData, TemplateDataAttributes } from '../useFetchTemplate'

import { useFetchWithDefaults } from '../../useFetchWithDefaults'

export const responseDataTypeName = 'userTemplate'

interface Relationships {
  template?: Relationship<TemplateData>
}

export type IncludableFields = keyof Relationships

export type UserTemplateData = Model<
  Pick<
    TemplateDataAttributes,
    'createdAt'
    | 'updatedAt'
    | 'name'
    | 'contractName'
    | 'descriptionHtml'
    | 'shortDescription'
    | 'language'
  > & {
    templateId: string
    type: 'custom' | 'prefilled'
  },
  Relationships
> & {
  type: 'userTemplate'
  links: {
    self: string
    editorUrl: string
  }
}

export type TailoredTemplateData = Model<
  Pick<
    TemplateDataAttributes,
    'createdAt'
    | 'updatedAt'
    | 'name'
    | 'contractName'
    | 'descriptionHtml'
    | 'shortDescription'
    | 'language'
    | 'categoryMetas'
  > & {
    type: 'tailored'
  }
> & {
  type: 'template'
}

export const useFetchUserTemplate = () => {
  const { get, post, put, deleteRequest } = useFetchWithDefaults()
  const url = '/me/userTemplates'
  const { sortIntl } = useIntl()

  const getById = (
    {
      id,
      fieldsToInclude,
      workspaceId,
    }: {
      id: string
      fieldsToInclude?: IncludableFields[]
    } & WorkspaceIdentifier) => get<Response<UserTemplateData>>({
    url: `${url}/${id}`,
    workspaceId,
    fetchOptions: { params: {
      ...(fieldsToInclude ? { include: fieldsToInclude } : {}),
    } },
  }).then(populateRelationships)

  const getAll = async (
    {
      fieldsToInclude,
      workspaceId,
    }: {
      fieldsToInclude?: IncludableFields[]
    } & WorkspaceIdentifier) => {
    const tailoredTemplates = await get<Response<TailoredTemplateData[]>>({
      url: '/me/templates',
      workspaceId,
      fetchOptions: {
        params: {
          limit: 999,
        },
      },
    }).then(({ data }) => data)

    const userTemplates = await get<Response<UserTemplateData[]>>({
      url,
      workspaceId,
      fetchOptions: { params: {
        limit: 999,
        ...(fieldsToInclude ? { include: fieldsToInclude } : {}),
      } },
    }).then(populateRelationships)

    return {
      ...userTemplates,
      data: sortIntl([...tailoredTemplates, ...userTemplates.data], ({ attributes: { name } }) => name),
    }
  }

  const create = (
    {
      name,
      id,
      fieldsToInclude,
      workspaceId,
    }: {
      name: string
      id?: string
      fieldsToInclude?: IncludableFields[]
    } & WorkspaceIdentifier) => post<Response<UserTemplateData>>({
    url,
    workspaceId,
    fetchOptions: {
      body: {
        name,
        ...(id ? { template_id: id } : {}),
      },
      params: {
        ...(fieldsToInclude ? { include: fieldsToInclude } : {}),
      },
    },
  })

  const update = (
    {
      id,
      attributes,
      fieldsToInclude,
      workspaceId,
    }: {
      id: string
      attributes: UserTemplateData['attributes']
      fieldsToInclude?: IncludableFields[]
    } & WorkspaceIdentifier,
  ) => put<Response<UserTemplateData>>({
    url: `${url}/${id}`,
    workspaceId,
    fetchOptions: {
      body: { ...attributes },
      params: {
        ...(fieldsToInclude ? { include: fieldsToInclude } : {}),
      },
    },
  })

  const drop = ({
    id,
    workspaceId,
  }: {
    id: string
  } & WorkspaceIdentifier) => deleteRequest({
    url: `${url}/${id}`,
    workspaceId,
  })

  return {
    getById,
    getAll,
    create,
    update,
    drop,
  }
}
