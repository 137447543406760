import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { useQueryDocumentSummary } from './summary/useQueryDocumentSummary'
import { useQueryDocumentReminder } from './reminder/useQueryDocumentReminder'
import { type IncludableField, type ParametersGetAll, responseDataTypeName, useFetchDocument } from './useFetchDocument'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    fieldsToInclude = [],
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: string[]
    params?: MaybeRef<ParametersGetAll>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
      ...(params ? [params] : []),
    ],
  }),

  byId: ({
    id,
    fieldsToInclude = [],
  }:
  {
    id: MaybeRef<string>
    fieldsToInclude?: string[]
  }) => ({
    queryKey: [
      id,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
    ],
  }),
})

const all = <T extends IncludableField[] = []>(
  {
    params,
    fieldsToInclude,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAll>
    fieldsToInclude?: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAll } = useFetchDocument()
  const { activeWorkspaceId } = useAuth()

  return useQuery(
    {
      ...queryKeys.all({
        workspaceId: activeWorkspaceId as MaybeRef<string>,
        fieldsToInclude,
        params,
      }),
      queryFn: () => getAll({
        params: unref(params),
        workspaceId: unref(activeWorkspaceId) ?? '',
        fieldsToInclude: fieldsToInclude ?? [],
      }),
      enabled: () => !!unref(activeWorkspaceId),
      ...useQueryOptions,
    },
  )
}

const byId = <T extends IncludableField[] = []>(
  {
    id,
    fieldsToInclude,
    useQueryOptions = {},
  }:
  {
    id: MaybeRef<string>
    fieldsToInclude?: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  },
) => {
  const { getById } = useFetchDocument()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.byId({
      id,
      fieldsToInclude,
    }),
    queryFn: () => getById({
      id: unref(id),
      fieldsToInclude,
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ data }) => data,
    ...useQueryOptions,
  })
}

export const useQueryDocument = {
  all,
  byId,
  summary: useQueryDocumentSummary,
  reminder: useQueryDocumentReminder,
}
