import { camelCase, snakeCase } from 'scule'

import type { AnyTemplate, V1Template, V2Template } from '~/types'

import type { TemplateData } from '../composables/api/template/useFetchTemplate'
import type { UserTemplateData } from '../composables/api/template/user-template/useFetchUserTemplate'

export const isV2Template = (t: AnyTemplate): t is V2Template => '_type' in t

export const getTemplateType = (template: AnyTemplate) => isV2Template(template) ? template._type : template.type

/* eslint-disable func-style */
export function getTemplateField<T extends keyof V1Template['attributes']>(fieldName: T, template: AnyTemplate): V1Template['attributes'][T]
export function getTemplateField<T extends keyof V2Template>(fieldName: T, template: AnyTemplate): V2Template[T]
export function getTemplateField<T extends (keyof V1Template['attributes'] | keyof V2Template)>(
  fieldName: T,
  template: AnyTemplate,
) {
  return isV2Template(template)
    ? template[snakeCase(fieldName) as keyof V2Template]
    : template.attributes[camelCase(fieldName) as keyof V1Template['attributes']]
}
/* eslint-enable func-style */

export const isGenericV2Template = (template: AnyTemplate): template is App.Data.Template.TemplateData =>
  isV2Template(template) && template._type === 'template'

export const isV2UserTemplate = (template: AnyTemplate): template is App.Data.UserTemplate.UserTemplateData =>
  getTemplateType(template) === 'user_template'

export const isUserTemplate = (template: AnyTemplate): template is UserTemplateData =>
  getTemplateType(template) === 'userTemplate'

export const isGenericTemplate = (template: AnyTemplate):
  template is TemplateData & { attributes: { type: 'generic' } } =>
  !isV2Template(template) && !isUserTemplate(template) && getTemplateField('type', template) === 'generic'

export const isTailoredTemplate = (template: AnyTemplate):
  template is TemplateData & { attributes: { type: 'tailored' } } =>
  !isV2Template(template) && !isUserTemplate(template) && getTemplateField('type', template) === 'tailored'

export const isPrefilledUserTemplate = (template: AnyTemplate):
  template is UserTemplateData & { attributes: { type: 'prefilled' } } =>
  isUserTemplate(template) && getTemplateField('type', template) === 'prefilled'

export const isCustomUserTemplate = (template: AnyTemplate):
  template is UserTemplateData & { attributes: { type: 'custom' } } =>
  isUserTemplate(template) && getTemplateField('type', template) === 'custom'
