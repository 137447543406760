import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import type { EstimatePayload } from './useFetchSubscriptionEstimate'

import { responseDataTypeName, useFetchSubscriptionEstimate } from './useFetchSubscriptionEstimate'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  getEstimate: ({
    workspaceId,
    payload,
  }: {
    workspaceId: MaybeRef<string>
    payload: MaybeRef<EstimatePayload>
  }) => ({
    queryKey: [
      workspaceId,
      payload,
    ],
  }),
  getScheduleEstimate: ({
    workspaceId,
    subscriptionId,
    payload,
  }: {
    workspaceId: MaybeRef<string>
    subscriptionId: MaybeRef<string>
    payload: MaybeRef<App.Data.Payloads.Subscription.UpdateSubscriptionSchedulePayload>
  }) => ({
    queryKey: [
      workspaceId,
      subscriptionId,
      payload,
    ],
  }),
})

const getEstimate = ({
  payload,
  useQueryOptions = {},
}: {
  payload: MaybeRef<EstimatePayload>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getEstimate } = useFetchSubscriptionEstimate()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getEstimate({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      payload,
    }),
    queryFn: () => getEstimate({
      workspaceId: unref(activeWorkspaceId) ?? '',
      payload: unref(payload),
    }),
    ...useQueryOptions,
  })
}

const getScheduleEstimate = ({
  subscriptionId,
  payload,
  useQueryOptions = {},
}: {
  subscriptionId: MaybeRef<string>
  payload: MaybeRef<App.Data.Payloads.Subscription.UpdateSubscriptionSchedulePayload>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getScheduleEstimate } = useFetchSubscriptionEstimate()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getScheduleEstimate({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      subscriptionId,
      payload,
    }),
    queryFn: () => getScheduleEstimate({
      workspaceId: unref(activeWorkspaceId) ?? '',
      subscriptionId: unref(subscriptionId),
      payload: unref(payload),
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQuerySubscriptionEstimate = {
  getEstimate,
  getScheduleEstimate,
}
