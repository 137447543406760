import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import type { IncludableFields } from './useFetchUserTemplate'

import { responseDataTypeName, useFetchUserTemplate } from './useFetchUserTemplate'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    fieldsToInclude,
  }: {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: IncludableFields[]
  }) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
    ],
  }),

  byId: ({
    id,
    fieldsToInclude,
  }: {
    id?: MaybeRef<string>
    fieldsToInclude?: string[]
  }) => ({ queryKey: [{
    id,
    ...(fieldsToInclude ? { fieldsToInclude: fieldsToInclude?.sort() } : {}),
  }] }),
})

const all = ({
  useQueryOptions = {},
  fieldsToInclude = [],
}: {
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
  fieldsToInclude?: IncludableFields[]
} = {}) => {
  const { activeWorkspaceId } = useAuth()
  const { getAll } = useFetchUserTemplate()

  return useQuery({
    ...queryKeys.all({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      fieldsToInclude,
    }),
    queryFn: () => getAll({ fieldsToInclude: unref(fieldsToInclude), workspaceId: unref(activeWorkspaceId) || '' }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const byId = ({
  id,
  useQueryOptions = {},
  fieldsToInclude = [],
}: {
  id: MaybeRef<string>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
  fieldsToInclude?: IncludableFields[]
}) => {
  const { activeWorkspaceId } = useAuth()
  const { getById } = useFetchUserTemplate()

  return useQuery({
    ...queryKeys.byId({ id, fieldsToInclude }),
    queryFn: () => getById({
      id: unref(id),
      workspaceId: unref(activeWorkspaceId) || '',
      fieldsToInclude: unref(fieldsToInclude),
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryUserTemplate = {
  all,
  byId,
}
