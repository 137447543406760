<script lang="ts" setup>
import LineXVue from '@docue/docue-ui-v2/icons/LineX.vue'
import LineEye from '@docue/docue-ui-v2/icons/LineEye.vue'
import LineClock from '@docue/docue-ui-v2/icons/LineClock.vue'
import LineType01 from '@docue/docue-ui-v2/icons/LineType01.vue'
import LineHash02 from '@docue/docue-ui-v2/icons/LineHash02.vue'

const props = defineProps<{
  property: App.Data.Document.Metadata.PropertyData
}>()

defineEmits<{
  select: []
}>()

const { t } = useI18n()
const { activeWorkspaceId } = useAuth()
const {
  mutateAsync: deleteMetaProperty,
  isPending,
} = useMutationDocumentProperty.drop()
const { confirmDelete } = useDialog()

const icons = {
  string: markRaw(LineType01),
  boolean: markRaw(LineEye),
  numeric: markRaw(LineHash02),
  date: markRaw(LineClock),
}

const deleteProperty = async () => {
  if (!(await confirmDelete({ message: t('settings.custom-property.delete-confirm') })))
    return

  try {
    await deleteMetaProperty({
      id: props.property.id,
      workspaceId: activeWorkspaceId.value ?? '',
    })
  }
  catch (e) {
    console.error(e)
  }
}
</script>

<template>
  <div
    class="flex max-w-full items-stretch rounded-md border border-gray-200 bg-gray-50 text-sm"
    :class="{
      'cursor-not-allowed opacity-[.4]': isPending,
    }"
  >
    <button
      type="button"
      class="
          flex
          min-w-0
          items-center
          gap-1
          rounded-l-md
          py-1
          pl-2.5
          pr-1
          text-left
          hover:bg-gray-100
          "
      @click="$emit('select')"
    >
      <Component
        :is="icons[property.data_type]"
        class="size-3.5 shrink-0"
      />
      <span class="truncate">{{ property.title }}</span>
    </button>
    <button
      :disabled="isPending"
      class="flex items-center rounded-r-md py-1 pl-1 pr-2.5 hover:bg-gray-100"
      @click="deleteProperty"
    >
      <DTSpinner
        v-if="isPending"
        :size="4"
        :weight="2"
      />
      <LineXVue
        v-else
        class="size-3.5"
      />
    </button>
  </div>
</template>
