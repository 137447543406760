import type { ISO8601Date } from '~/types'
import type { Model, Response } from '~/types/api/v1'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

interface AccountItemMetaTypeI {
  createdAt: ISO8601Date
  updatedAt: ISO8601Date
  title: string
  type: 'custom' | 'system-category'
}

export type AccountItemMetaType = Model<AccountItemMetaTypeI>

export const useFetchAccountItemMetaType = () => {
  const url = '/me/account/accountItemMetaTypes'
  const { get, post, put, deleteRequest } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<Response<AccountItemMetaType[]>>({
      url,
      workspaceId,
    })

  const create = (
    {
      workspaceId,
      title,
    }: WorkspaceIdentifier & { title: string }) =>
    post<Response<AccountItemMetaType>>({
      url,
      workspaceId,
      fetchOptions: {
        body: {
          data: {
            attributes: {
              title,
            },
          },
        },
      },
    })

  const update = ({
    workspaceId,
    typeId,
    title,
  }: WorkspaceIdentifier & { typeId: string, title: string }) => put<Response<AccountItemMetaType>>({
    url: `${url}/${typeId}`,
    workspaceId,
    fetchOptions: {
      body: {
        data: {
          id: typeId,
          attributes: { title },
        },
      },
    },
  })

  const drop = ({ workspaceId, typeId }: WorkspaceIdentifier & { typeId: string }) =>
    deleteRequest({
      url: `${url}/${typeId}`,
      workspaceId,
    })

  return {
    getAll,
    create,
    update,
    drop,
  }
}
