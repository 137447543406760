import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { type ParametersGetAll, responseDataTypeName, useFetchFavourite } from './useFetchFavourite'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    params,
    workspaceId,
  }: {
    params?: MaybeRef<ParametersGetAll>
    workspaceId: MaybeRef<string>
  }) => ({ queryKey: [
    workspaceId,
    ...(params ? [params] : []),
  ] }),
})

const all = ({
  params,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetAll>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchFavourite()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({
      params,
      workspaceId: activeWorkspaceId as MaybeRef<string>,
    }),
    queryFn: () => getAll({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryFavourite = {
  all,
}
