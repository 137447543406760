import type { ISO8601Date, SubscriptionPaymentPeriodUnit } from '~~/types'

import type { Workspace } from '~/types/auth'
import type { Model, Relationship, Response } from '~/types/api/v1'
import type { Response as ApiResponse, CollectionResponse } from '~/types/api/v2'

import { populateRelationships } from '~/utils/api/v1'

import type { WorkspaceIdentifier } from '../types'
import type { PriceListAddonPrice, PriceListItemPrice } from '../price-list/useFetchPriceList'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName: Subscription['type'] = 'subscription'

export type IncludableFields = 'addons' | 'contractTerm'

export type SubscriptionAddon = Model<{
  quantity: number
  unitPrice: number
}> & { type: 'subscriptionAddon' }

export type SubscriptionContractTerm = Model<{
  status: string
  termEndAction: string
  contractStart: ISO8601Date
  contractEnd: ISO8601Date
}>

interface Relationships {
  addons?: Relationship<SubscriptionAddon[]>
  contractTerm?: Relationship<SubscriptionContractTerm>
}

export type SubscriptionStatus =
  'paused'
  | 'active'
  | 'future'
  | 'in_trial'
  | 'cancelled'
  | 'non_renewing'

export type Subscription = Model<{
  createdAt: ISO8601Date
  status: SubscriptionStatus
  cancelledAt: string | null
  planName: string | null
  planId?: Maybe<string>
  firstInvoiceDate: ISO8601Date | null
  trialEnd: ISO8601Date | null
  startDate: ISO8601Date | null
  trialEndAction: string | null
  billingPeriod: Maybe<SubscriptionPaymentPeriodUnit>
  monthlyPrice: Maybe<number>
}, Relationships> & {
  type: 'subscription'
}

export interface UpdateWorkspaceBillingPayload extends Partial<Pick<
  Workspace['attributes'],
  'billingAddress'
  | 'billingCity'
  | 'billingCompanyIdent'
  | 'billingCompanyName'
  | 'billingFirstName'
  | 'billingLastName'
  | 'billingCountry'
  | 'billingPostCode'
  | 'billingVatNumber'
  | 'einvoiceAccount'
  | 'einvoiceOperator'
  | 'email'
  | 'billingPhone'
>> {}

export type SubscriptionPaymentMethod = App.Data.Configuration.PaymentMethodConfigurationData['subscriptions'][number]

interface CreateSubscriptionCommonPayload extends UpdateWorkspaceBillingPayload {
  planPriceId: PriceListItemPrice['id']
  paymentMethod: SubscriptionPaymentMethod
  couponId?: string
  addonPriceId?: Maybe<PriceListAddonPrice['price_id']>
  addonQuantity?: number
}

export type CreateSubscriptionPaymentIntentData = ({
  stripePaymentIntentId?: string
  chargebeePaymentIntentId?: never
} | {
  chargebeePaymentIntentId?: string
  stripePaymentIntentId?: never
})
export type CreateSubscriptionPayload = CreateSubscriptionCommonPayload & CreateSubscriptionPaymentIntentData
export type SubscriptionGetAllIncludableFields = 'scheduled_changes'

export const useFetchSubscription = () => {
  const legacyCreateUrl = (workspaceId: string) => `/me/companies/${workspaceId}/subscription`
  const url = '/v2/subscriptions'
  const urlBySubscriptionId = (subscriptionId: string) => `${url}/${subscriptionId}`
  const scheduleUrlBySubscriptionId = (subscriptionId: string) => `${urlBySubscriptionId(subscriptionId)}/schedule`

  const { get, post, put } = useFetchWithDefaults()

  const getAll = ({
    workspaceId,
    fieldsToInclude,
  }: WorkspaceIdentifier & { fieldsToInclude?: SubscriptionGetAllIncludableFields[] }) =>
    get<CollectionResponse<App.Data.Subscription.SubscriptionData>>({
      url,
      workspaceId,
      fetchOptions: {
        params: {
          ...(fieldsToInclude?.length ? { include: fieldsToInclude } : {}),
        },
      },
    })

  const create = ({
    workspaceId,
    payload: {
      paymentMethod,
      planPriceId,
      addonPriceId,
      addonQuantity,
      couponId,
      stripePaymentIntentId,
      chargebeePaymentIntentId,
      ...rest
    },
    fieldsToInclude,
  }: WorkspaceIdentifier & {
    payload: CreateSubscriptionPayload
    fieldsToInclude?: IncludableFields[]
  }) => post<Response<Subscription>>({
    url: legacyCreateUrl(workspaceId),
    workspaceId,
    fetchOptions: {
      body: {
        plan: planPriceId,
        paymentMethod,
        addons: addonPriceId ? [{ id: addonPriceId, quantity: addonQuantity || 1 }] : [],
        ...(couponId ? { coupon: couponId } : {}),
        ...(stripePaymentIntentId ? { stripePaymentIntentId } : {}),
        ...(chargebeePaymentIntentId ? { chargebeePaymentIntentId } : {}),
        ...rest,
      },
      params: {
        ...(fieldsToInclude ? { include: fieldsToInclude } : {}),
      },
    },
  }).then(populateRelationships)

  const scheduleUpdate = ({
    workspaceId,
    subscriptionId,
    payload,
  }: WorkspaceIdentifier & { subscriptionId: string, payload: App.Data.Payloads.Subscription.UpdateSubscriptionSchedulePayload }) =>
    put<ApiResponse<App.Data.Subscription.SubscriptionData>>({
      url: scheduleUrlBySubscriptionId(subscriptionId),
      workspaceId,
      fetchOptions: {
        body: {
          action: payload.action,
          ...(payload.plan_price_id ? { plan_price_id: payload.plan_price_id } : {}),
          ...(payload.addon_ids ? { addon_ids: payload.addon_ids } : {}),
        },
      },
    })

  return {
    getAll,
    create,
    scheduleUpdate,
  }
}
