import type { Response } from '~/types/api/v2'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName: App.Data.Utils.RatingData['_type'] = 'rating'

export const useFetchRating = () => {
  const url = '/v2/utils/rating'

  const { get } = useFetchWithDefaults()

  const getRating = ({ workspaceId }: WorkspaceIdentifier) => get<Response<App.Data.Utils.RatingData>>({ workspaceId, url })

  return {
    getRating,
  }
}
