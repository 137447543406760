import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { queryKeys as featureQueryKeys } from '~/composables/api/feature/useQueryFeature'
import { queryKeys as balanceQueryKeys } from '~/composables/api/balance/useQueryBalance'
import { queryKeys as configQueryKeys } from '~/composables/api/config/useQueryConfig.js'
import { queryKeys as licenseQueryKeys } from '~/composables/api/license/useQueryLicense'
import { queryKeys as paymentSourceQueryKeys } from '~/composables/api/payment-source/useQueryPaymentSource'
import { queryKeys as workspacePermissionQueryKeys } from '~/composables/api/workspace-permission/useQueryWorkspacePermission'

import { useQueryClientUtils } from '../useQueryClientUtils'
import { responseDataTypeName, type SubscriptionGetAllIncludableFields, useFetchSubscription } from './useFetchSubscription'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    fieldsToInclude = [],
  }: {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: SubscriptionGetAllIncludableFields[]
  }) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude?.length ? [fieldsToInclude.sort()] : []),
    ],
  }),
})

const all = <T extends SubscriptionGetAllIncludableFields[] = []>({
  useQueryOptions = {},
  fieldsToInclude,
}: {
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
  fieldsToInclude?: T
} = {}) => {
  const { getAll } = useFetchSubscription()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      fieldsToInclude,
    }),
    queryFn: () => getAll({
      workspaceId: unref(activeWorkspaceId) ?? '',
      fieldsToInclude: fieldsToInclude ?? [],
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const create = () => {
  const { create: mutationFn } = useFetchSubscription()
  const { invalidateQueries, cancelQueries } = useQueryClientUtils()
  const { fetchActiveWorkspaceData } = useAuth()

  return useMutation({
    mutationFn,
    onMutate: () => {
      cancelQueries([
        queryKeys.all._def,
        featureQueryKeys.all._def,
        balanceQueryKeys.all._def,
        configQueryKeys.current._def,
        licenseQueryKeys.all._def,
        workspacePermissionQueryKeys.all._def,
        paymentSourceQueryKeys.all._def,
      ])
    },
    onSettled: () => {
      fetchActiveWorkspaceData({ refresh: true })
      invalidateQueries([
        queryKeys.all._def,
        featureQueryKeys.all._def,
        balanceQueryKeys.all._def,
        configQueryKeys.current._def,
        licenseQueryKeys.all._def,
        workspacePermissionQueryKeys.all._def,
        paymentSourceQueryKeys.all._def,
      ])
    },
  })
}

const scheduleUpdate = () => {
  const { scheduleUpdate: mutationFn } = useFetchSubscription()
  const { invalidateQueries, cancelQueries } = useQueryClientUtils()

  return useMutation({
    mutationFn,
    onMutate: () => {
      cancelQueries([
        queryKeys.all._def,
      ])
    },
    onSettled: () => {
      invalidateQueries([
        queryKeys.all._def,
      ])
    },
  })
}

export const useQuerySubscription = {
  all,
  create,
  scheduleUpdate,
}
