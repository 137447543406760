import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataType, useFetchLegalScoreCategory } from './useFetchLegalScoreCategory'

export const queryKeys = createQueryKeys(responseDataType, {
  all: ({
    workspaceId,
    locale,
  }: {
    workspaceId: MaybeRef<string>
    locale: MaybeRef<App.Internals.Enums.Language>
  }) => ({
    queryKey: [{
      workspaceId,
      locale,
    }],
  }),
})

const all = ({
  useQueryOptions = {},
}: {
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchLegalScoreCategory()
  const { activeWorkspaceId } = useAuth()
  const { language } = useLanguage()

  return useQuery({
    queryKey: queryKeys.all({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      locale: language,
    }).queryKey,
    queryFn: () => getAll({
      workspaceId: unref(activeWorkspaceId) ?? '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryLegalScoreCategory = {
  all,
}
