import type { CollectionResponse } from '~/types/api/v2'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

type OnboardingTaskData = App.Data.OnboardingTaskData

export const responseDataTypeName: OnboardingTaskData['_type'] = 'onboarding_task'

export const useFetchOnboardingTask = () => {
  const url = '/v2/onboarding-tasks'

  const { get, patch } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) => get<CollectionResponse<OnboardingTaskData>>({
    url,
    workspaceId,
  })

  const update = ({
    workspaceId,
    payload,
  }: WorkspaceIdentifier & {
    payload: App.Data.Payloads.UpdateOnboardingTaskPayload
  }) => patch<CollectionResponse<OnboardingTaskData>>({
    url,
    workspaceId,
    fetchOptions: {
      body: payload,
    },
  })

  return {
    getAll,
    update,
  }
}
