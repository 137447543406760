import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchPreferences } from './useFetchPreferences'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  getByKey: ({ key }: { key: MaybeRef<string> }) => ({ queryKey: [key] }),
})

const getByKey = ({ key, useQueryOptions }: { key: MaybeRef<string>, useQueryOptions?: UseQueryOptionsWithoutQueryFn }) => {
  const { getByKey } = useFetchPreferences()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getByKey({ key }),
    queryFn: () => getByKey({
      key: unref(key),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryPreferences = {
  getByKey,
}
