<script lang="ts" setup>
import LineBell01 from '@docue/docue-ui-v2/icons/LineBell01.vue'
import LineMenu01 from '@docue/docue-ui-v2/icons/LineMenu01.vue'
import LineGlobe01 from '@docue/docue-ui-v2/icons/LineGlobe01.vue'

import type * as API from '~/types/api'
import type { SignInvitation } from '~/types/sign'

const { t } = useI18n()

const { data: pendingSignInvitations, pending } = useAsyncData(
  () => useApi().getRequest<API.v1.Response<SignInvitation[]>>(
    '/me/sign-invitations?signatureState=waiting',
  )().then(r => r.data),
)

const signInvitationCount = computed(() => pendingSignInvitations.value?.length ?? 0)

const notificationsTooltip = computed(() => ({
  content: t('header.notifications.tooltip'),
  variant: 'dark',
  placement: 'bottom',
  // TODO: Add tooltip size sm|xs?
  tooltipClass: '!py-1 !px-2',
  trigger: ['click', 'hover'],
}))
</script>

<template>
  <header class="flex h-14 w-full shrink-0 border-b border-b-gray-200">
    <div class="flex grow items-center justify-between gap-5 px-4 py-2.5">
      <AppUserMenu />
      <AppSearch />
      <div class="flex items-center gap-4">
        <DTPopover>
          <template #button>
            <DTPopoverButton class="relative rounded-full">
              <DTIconButton
                v-dt-tooltip="notificationsTooltip"
                variant="plain"
                as="div"
              >
                <LineBell01 class="w-5 text-gray-600" />
              </DTIconButton>
              <span
                v-if="signInvitationCount > 0"
                class="
                    absolute
                    -right-1.5
                    top-0
                    flex
                    size-4
                    items-center
                    justify-center
                    rounded-full
                    border-[1.5px]
                    border-white
                    bg-red-500
                    text-[8px]
                    text-white
                    "
              >
                {{ signInvitationCount }}
              </span>
            </DTPopoverButton>
          </template>

          <div class="min-w-max px-3.5 py-4 pl-6">
            <DTLoaderDots v-if="pending" />
            <span
              v-else-if="signInvitationCount > 0"
              class="grid grid-cols-[min-content,auto] gap-3"
            >
              <i
                class="inline-block size-1.5 self-center rounded-full bg-red-500"
              />
              <NuxtLink
                to="/sign/invitations"
                class="text-sm"
              >
                {{
                  $t('header.notifications.pending-signatures', {
                    n: signInvitationCount,
                    count: signInvitationCount,
                  })
                }}
              </NuxtLink>
            </span>
            <span
              v-else
              class="-ml-3 text-sm text-gray-600"
            >
              {{ $t('header.notifications.empty') }}
            </span>
          </div>
        </DTPopover>

        <AppLocaleDropdown
          :menu-options="{
            offset: 6,
            placement: 'bottom-end',
          }"
        >
          <template #button>
            <DTDropdownButton
              is="DTIconButton"
              v-dt-tooltip="{
                content: t('header.language.tooltip'),
                variant: 'dark',
                placement: 'bottom',
                tooltipClass: '!py-1 !px-2',
                trigger: ['hover'],
              }"
              variant="plain"
              wrapper-class="[&>button]:rounded-full"
            >
              <LineGlobe01 class="w-5 text-gray-600" />
            </DTDropdownButton>
          </template>
        </AppLocaleDropdown>
        <DTPopover
          class="md:hidden"
          placement="bottom-end"
        >
          <template #button>
            <DTPopoverButton class="rounded-full">
              <DTIconButton
                as="div"
                variant="plain"
                dense
              >
                <LineMenu01 class="size-5 text-gray-600" />
              </DTIconButton>
            </DTPopoverButton>
          </template>
          <div class="p-1.5">
            <AppNavigation />
          </div>
        </DTPopover>
      </div>
    </div>
  </header>
</template>
