import type { WorkspaceIdentifier } from '../../types'

import { useFetchWithDefaults } from '../../useFetchWithDefaults'

export interface GetAllParams {
  query: string
  country: App.Internals.Enums.Country
  limit?: number
}

export interface IntelligenceCompany {
  title: string
  ident: string
  street: Maybe<string>
  city: Maybe<string>
  post_code: Maybe<string>
  country_code: App.Internals.Enums.Country
  einvoice_address: Maybe<string>
  einvoice_operator: Maybe<string>
}

export const responseDataTypeName = 'intelligence-companies'

export const useFetchIntelligenceCompanies = () => {
  const url = '/v2/intelligence/companies/search'
  const { get } = useFetchWithDefaults()

  const getAll = ({
    workspaceId,
    params: {
      query,
      country,
      limit = 5,
    },
  }: WorkspaceIdentifier & {
    params: GetAllParams
  }) => get<{ data: IntelligenceCompany[] }>({
    url,
    fetchOptions: {
      params: {
        q: query,
        country,
        limit,
      },
    },
    workspaceId,
  })

  return {
    getAll,
  }
}
