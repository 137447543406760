import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { Language } from '~/types'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import {
  type IncludableFields,
  type ParametersGetAll,
  type ParametersGetAllByCollectionId,
  responseDataTypeName,
  useFetchTemplate,
} from './useFetchTemplate'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byId: ({
    id,
    locale,
    fieldsToInclude,
  }: {
    id?: MaybeRef<string>
    locale: MaybeRef<Language>
    fieldsToInclude?: IncludableFields[]
  }) => ({ queryKey: [{
    id,
    locale,
    ...(fieldsToInclude ? { fieldsToInclude: fieldsToInclude?.sort() } : {}),
  }] }),

  all: ({
    params,
    locale,
    workspaceId,
  }: {
    params?: MaybeRef<ParametersGetAll>
    locale: MaybeRef<Language>
    workspaceId: MaybeRef<string>
  }) => ({
    queryKey: [
      workspaceId,
      locale,
      ...(params ? [params] : []),
    ],
  }),

  allByCollectionId: ({
    collectionId,
    locale,
    params,
  }: {
    collectionId: MaybeRef<string>
    locale: MaybeRef<Language>
    params?: MaybeRef<ParametersGetAllByCollectionId>
  }) => ({
    queryKey: [
      collectionId,
      locale,
      ...(params ? [params] : []),
    ],
  }),

  related: ({ id }: { id: MaybeRef<string> }) => ({ queryKey: [id] }),
})

const byId = ({
  id,
  useQueryOptions = {},
  fieldsToInclude = [],
}: {
  id: MaybeRef<string>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
  fieldsToInclude?: IncludableFields[]
}) => {
  const { getById } = useFetchTemplate()
  const { activeWorkspaceId } = useAuth()
  const { language } = useLanguage()

  return useQuery({
    ...queryKeys.byId({ id, fieldsToInclude, locale: language }),
    queryFn: () => getById({
      id: unref(id),
      fieldsToInclude: unref(fieldsToInclude),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const all = ({
  params,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetAll>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchTemplate()
  const { activeWorkspaceId } = useAuth()
  const { language } = useLanguage()

  return useQuery({
    ...queryKeys.all({
      params,
      locale: language,
      workspaceId: activeWorkspaceId as MaybeRef<string>,
    }),
    queryFn: () => getAll({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const allByCollectionId = ({
  collectionId,
  params,
  useQueryOptions,
}: {
  collectionId: MaybeRef<string>
  params?: MaybeRef<ParametersGetAllByCollectionId>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getAllByCollectionId } = useFetchTemplate()
  const { activeWorkspaceId } = useAuth()
  const { language } = useLanguage()

  return useQuery({
    ...queryKeys.allByCollectionId({
      collectionId,
      locale: language,
      params,
    }),
    queryFn: () => getAllByCollectionId({
      collectionId: unref(collectionId),
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const related = (
  {
    id,
    useQueryOptions = {},
  }:
  {
    id: MaybeRef<string>
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  },
) => {
  const { getRelated } = useFetchTemplate()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.related({ id }),
    queryFn: () => getRelated({
      id: unref(id),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryTemplate = {
  byId,
  related,
  all,
  allByCollectionId,
}
