import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchOnboardingTask } from './useFetchOnboardingTask'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const all = (useQueryOptions: UseQueryOptionsWithoutQueryFn = {}) => {
  const { getAll } = useFetchOnboardingTask()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) || '' }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryOnboardingTask = {
  all,
}
