import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchConfig } from './useFetchConfig'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  current: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const current = ({ useQueryOptions = {} }: { useQueryOptions?: UseQueryOptionsWithoutQueryFn } = {}) => {
  const { getCurrent } = useFetchConfig()
  const { activeWorkspaceId } = useAuth()

  const cachedData = getLegacyCacheData('config', activeWorkspaceId.value)

  return useQuery({
    ...queryKeys.current({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getCurrent({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ data }) => data,
    ...(
      cachedData
        ? {
            initialData: (): { data: App.Data.Configuration.ConfigurationData } => ({ data: cachedData }),
            initialDataUpdatedAt: 0, // to start fetching in the background
          }
        : {}
    ),
    ...useQueryOptions,
  })
}

export const useQueryConfig = ({
  current,
})
