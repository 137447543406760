import type { Response } from '~/types/api/v2'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export interface ParametersGetAll {
  query: string
  per_page?: number
}

export type ResponseDataTypeName = 'folder' | 'template,user_template' | 'template' | 'user_template'
export type ResponseTypeGetAllFolders = Response<App.Data.Folder.FolderData[]>
export type ResponseTypeGetAllTemplates = Response<App.Data.Template.TemplateData[]>
export type ResponseTypeGetAllTemplatesAndUserTemplates = Response<
  (App.Data.Template.TemplateData | App.Data.UserTemplate.UserTemplateData)[]
>

export const useFetchSearch = () => {
  const url = '/v2/search'

  const { get } = useFetchWithDefaults()

  const createGetAllFunction = <ResponseT extends Response<any[]>>(typeName: ResponseDataTypeName) => ({
    params,
    workspaceId,
  }: WorkspaceIdentifier & {
    params?: ParametersGetAll
  }) =>
    get<ResponseT>({
      url,
      workspaceId,
      fetchOptions: {
        params: {
          ...params,
          'filter[type]': typeName,
        },
      },
    })

  const getAllFolders = createGetAllFunction<ResponseTypeGetAllFolders>('folder')
  const getAllTemplates = createGetAllFunction<ResponseTypeGetAllTemplates>('template')
  const getAllTemplatesAndUserTemplates
    = createGetAllFunction<ResponseTypeGetAllTemplatesAndUserTemplates>('template,user_template')

  return {
    getAllFolders,
    getAllTemplates,
    getAllTemplatesAndUserTemplates,
  }
}
