import type { Response } from '~/types/api/v1'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

type AccountItemStage = 'created' | 'finalized' | 'completed' | 'preview' | 'template_preview' | 'cancelled'

export interface ParametersGetAll {
  stage: AccountItemStage
  limit?: number
}

interface AccountItem {
  id: string
  type: 'accountItem'
  attributes: {
    stage: AccountItemStage
  }
}

export const responseDataType: AccountItem['type'] = 'accountItem'

export const useFetchAccountItem = () => {
  const { get } = useFetchWithDefaults()

  const url = '/me/account/items'

  const getAll = ({ workspaceId, params }: WorkspaceIdentifier & { params: ParametersGetAll }) =>
    get<Response<AccountItem>>({
      url,
      workspaceId,
      fetchOptions: {
        params,
      },
    })

  return {
    getAll,
  }
}
