import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchPriceList } from './useFetchPriceList'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  getByName: ({
    workspaceId,
    name,
  }: {
    workspaceId: MaybeRef<string>
    name: MaybeRef<string>
  }) => ({
    queryKey: [
      workspaceId,
      name,
    ],
  }),
})

const byName = (
  {
    name,
    useQueryOptions = {},
  }: {
    name: MaybeRef<string>
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  }) => {
  const { getByName } = useFetchPriceList()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getByName({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      name,
    }),
    queryFn: () => getByName({
      name: toValue(name),
      workspaceId: toValue(activeWorkspaceId) ?? '',
    }),
    ...useQueryOptions,
  })
}

export const useQueryPriceList = {
  byName,
}
