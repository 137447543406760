import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import type { PreviewSigneeInformation } from './useFetchContractSignaturePreview'

import { useFetchContractSignaturePreview } from './useFetchContractSignaturePreview'

const queryKeys = createQueryKeys('contract-signature-preview', {
  createPreview: ({
    signees,
    language,
  }: {
    signees: MaybeRef<Pick<PreviewSigneeInformation, 'firstName' | 'lastName' | 'email'>[]>
    language: MaybeRef<App.Internals.Enums.Language>
  }) => ({
    queryKey: [signees, language],
  }),
})

const createPreview = ({
  signees,
  language,
  useQueryOptions = {},
}: {
  signees: MaybeRef<Pick<PreviewSigneeInformation, 'firstName' | 'lastName' | 'email'>[]>
  language: MaybeRef<App.Internals.Enums.Language>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { createPreview } = useFetchContractSignaturePreview()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.createPreview({ signees, language }),
    queryFn: async () => {
      const responseBlob = await createPreview({
        signees: toValue(signees),
        workspaceId: unref(activeWorkspaceId) || '',
        language: toValue(language),
      })
      return new File([responseBlob], 'preview')
    },
    ...useQueryOptions,
  })
}

export const useQueryContractSignaturePreview = {
  createPreview,
}
