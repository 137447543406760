import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import {
  type ParametersGetAll,
  type ResponseDataTypeName,
  type ResponseTypeGetAllFolders,
  type ResponseTypeGetAllTemplates,
  type ResponseTypeGetAllTemplatesAndUserTemplates,
  useFetchSearch,
} from './useFetchSearch'

export const queryKeys = createQueryKeys('search', {
  all: ({
    // Having the type here is not optimal, but having a property per type is
    // too repetitive. A factory function is not an option because of missing
    // exported types (https://github.com/lukemorales/query-key-factory/issues/72).
    type,
    params,
    workspaceId,
  }:
  {
    type: ResponseDataTypeName
    params?: MaybeRef<ParametersGetAll>
    workspaceId: MaybeRef<string>
  },
  ) => ({
    queryKey: [
      workspaceId,
      { dataType: type },
      ...(params ? [params] : []),
    ],
  }),
})

interface QueryParameters {
  params?: MaybeRef<ParametersGetAll> | undefined
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}

type SupportedResponseType =
  ResponseTypeGetAllFolders |
  ResponseTypeGetAllTemplates |
  ResponseTypeGetAllTemplatesAndUserTemplates

type FetchFunction<RT extends SupportedResponseType> =
  ({ params, workspaceId }: {
    params?: ParametersGetAll | undefined
    workspaceId: string
  }) => Promise<RT>

const createUseQueryFunction = <RT extends SupportedResponseType>(
  fetchFunction: FetchFunction<RT>,
  type: ResponseDataTypeName,
) => (
  {
    params,
    useQueryOptions = {},
  }: QueryParameters = {},
) => {
  const { activeWorkspaceId } = useAuth()

  return useQuery(
    {
      ...queryKeys.all({
        type,
        params,
        workspaceId: activeWorkspaceId as MaybeRef<string>,
      }),
      queryFn: () => fetchFunction({
        params: unref(params),
        workspaceId: unref(activeWorkspaceId) ?? '',
      }),
      enabled: () => !!unref(activeWorkspaceId),
      ...useQueryOptions,
    },
  )
}

const allFolders = (params: QueryParameters) => {
  const { getAllFolders } = useFetchSearch()
  return createUseQueryFunction(getAllFolders, 'folder')(params)
}

const allTemplates = (params: QueryParameters) => {
  const { getAllTemplates } = useFetchSearch()
  return createUseQueryFunction(getAllTemplates, 'template')(params)
}

const allTemplatesAndUserTemplates = (params: QueryParameters) => {
  const { getAllTemplatesAndUserTemplates } = useFetchSearch()
  return createUseQueryFunction(getAllTemplatesAndUserTemplates, 'template,user_template')(params)
}

export const useQuerySearch = {
  allFolders,
  allTemplates,
  allTemplatesAndUserTemplates,
}
