import type { WithNegated } from '~/types/util/string'
import type { CollectionRequestPaginationParams } from '~/types/api/v2'

import { useApiV2FetchFactory } from '../useApiV2FetchFactory'

type FolderData = App.Data.Folder.FolderData
export const responseDataTypeName: FolderData['_type'] = 'folder'

export type IncludableField =
  'parent' |
  'child_ids' |
  'breadcrumb' |
  'parentExists' |
  'has_children' |
  'own_permissions' |
  'breadcrumbExists' |
  'parent.own_permissions'

type SortCriterion = 'name'

export type ParametersGetAll = {
  sort?: WithNegated<SortCriterion>[]
  'filter[parent_id]'?: string
} & CollectionRequestPaginationParams

export interface ParametersGetAllUnpaginated {
  sort?: WithNegated<SortCriterion>[]
  'filter[id]'?: string[]
  'filter[parent_id]'?: string
}

export type ParametersGetAllSharedWithMeUnpaginated =
 Omit<ParametersGetAllUnpaginated, 'filter[id]' | 'filter[parent_id]'>

export const useFetchFolder = () => {
  const url = '/v2/folders'

  const { createAllMethods } = useApiV2FetchFactory<FolderData, IncludableField>(url)

  type CreatePayload = App.Data.Payloads.Documents.CreateFolderPayload
  type UpdatePayload = App.Data.Payloads.Documents.UpdateFolderPayload

  const { createGetAllUnpaginated } = useApiV2FetchFactory<FolderData, IncludableField>(`${url}/shared-with-me`)

  const getSharedWithMeUnpaginated = createGetAllUnpaginated<ParametersGetAllSharedWithMeUnpaginated>()

  return {
    getSharedWithMeUnpaginated,
    ...createAllMethods<ParametersGetAllUnpaginated, CreatePayload, UpdatePayload>(),
  }
}
