import type { FetchOptions } from 'ofetch'

import type {
  CollectionRequestPaginationParams,
  CollectionResponse,
  IncludedFieldDependentResponse,
  Response,
} from '~/types/api/v2'

import type { WorkspaceIdentifier } from './types'

import { useFetchWithDefaults } from './useFetchWithDefaults'

export const useApiV2FetchFactory = <
  TFullResponseType,
  TIncludableField extends string,
>(url: string) => {
  const { post, get, put, deleteRequest } = useFetchWithDefaults()

  type DynamicResponse<TFieldsToInclude extends string[]> =
    IncludedFieldDependentResponse<
      TFullResponseType,
      TFieldsToInclude,
      TIncludableField
    >

  const createGetAll = <TParametersGetAll>(
    fetchOptions?: FetchOptions,
  ) => <TFieldsToInclude extends TIncludableField[] = []>(
    {
      params,
      workspaceId,
      fieldsToInclude,
    }:
      WorkspaceIdentifier & {
        params?: TParametersGetAll
        fieldsToInclude?: TFieldsToInclude
      },
  ) => get<CollectionResponse<DynamicResponse<TFieldsToInclude>>>({
    url,
    workspaceId,
    fetchOptions: {
      params: {
        ...params,
        ...(fieldsToInclude?.length ? { include: fieldsToInclude } : {}),
      },
      ...fetchOptions,
    },
  })

  const createGetAllUnpaginated
    = <TParametersGetAllUnpaginated>() => async <TFieldsToInclude extends TIncludableField[] = []>(
      {
        params,
        workspaceId,
        fetchOptions,
        fieldsToInclude,
      }:
        WorkspaceIdentifier & {
          params?: TParametersGetAllUnpaginated
          fetchOptions?: FetchOptions
          fieldsToInclude?: TFieldsToInclude
        },
    ) => {
      const getterFn = get<CollectionResponse<DynamicResponse<TFieldsToInclude>>>
      const getParams = (page: number) => ({
        url,
        workspaceId,
        fetchOptions: {
          params: {
            ...params,
            ...(fieldsToInclude?.length ? { include: fieldsToInclude } : {}),
            per_page: 200,
            page,
          },
          ...fetchOptions,
        },
      })

      const firstPage = await getterFn(getParams(1))

      if (firstPage.meta.last_page > 1) {
        const remainingPages = await Promise.all(
          Array.from(Array.from({ length: firstPage.meta.last_page })).slice(1).map((_, i) => i + 2)
            .map(page => getterFn(getParams(page))),
        )

        return {
          data: firstPage.data.concat(remainingPages.map(r => r.data).flat()),
        }
      }

      return {
        data: firstPage.data,
      }
    }

  const createGetById = () => <
    TFieldsToInclude extends TIncludableField[] = [],
  >({
    id,
    workspaceId,
    fieldsToInclude,
  }: WorkspaceIdentifier & {
    id: string
    fieldsToInclude?: TFieldsToInclude
  }) => get<Response<DynamicResponse<TFieldsToInclude>>>({
    url: `${url}/${id}`,
    workspaceId,
    fetchOptions: {
      ...(fieldsToInclude?.length
        ? { params: { include: fieldsToInclude } }
        : {}),
    },
  })

  const createCreate = <TPayload extends object>() => <TFieldsToInclude extends TIncludableField[] = []>(
    {
      payload,
      workspaceId,
      fieldsToInclude,
    }: WorkspaceIdentifier & {
      payload: TPayload
      fieldsToInclude?: TFieldsToInclude
    },
  ) =>
    post<Response<DynamicResponse<TFieldsToInclude>>>({
      url,
      workspaceId,
      fetchOptions: {
        body: payload,
        ...(
          fieldsToInclude?.length
            ? { params: { include: fieldsToInclude ?? [] } }
            : {}
        ),
      },
    })

  const createUpdate = <TPayload extends object>() => <TFieldsToInclude extends TIncludableField[] = []>(
    {
      id,
      payload,
      workspaceId,
      fieldsToInclude,
    }: WorkspaceIdentifier & {
      id: string
      payload: TPayload
      fieldsToInclude?: TFieldsToInclude
    }) =>
    put<Response<DynamicResponse<TFieldsToInclude>>>({
      url: `${url}/${id}`,
      workspaceId,
      fetchOptions: {
        body: payload,
        ...(fieldsToInclude?.length
          ? { params: { include: fieldsToInclude } }
          : {}),
      },
    })

  const createDrop = () => ({ workspaceId, id }: WorkspaceIdentifier & { id: string }) =>
    deleteRequest({
      url: `${url}/${id}`,
      workspaceId,
    })

  // TODO use following method in other composables or create linear ticket to make use of this composable
  const createAllMethods = <
    TParametersGetAllUnpaginated,
    TCreateEntityPayload extends object,
    TUpdateEntityPayload extends object,
  >() => ({
    getAll: createGetAll<TParametersGetAllUnpaginated & CollectionRequestPaginationParams>(),
    getAllUnpaginated: createGetAllUnpaginated<TParametersGetAllUnpaginated>(),
    getById: createGetById(),

    create: createCreate<TCreateEntityPayload>(),
    update: createUpdate<TUpdateEntityPayload>(),
    drop: createDrop(),
  })

  return {
    createGetAll,
    createGetAllUnpaginated,
    createGetById,

    createDrop,
    createCreate,
    createUpdate,

    createAllMethods,
  }
}
