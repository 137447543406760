import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchTemplateCollection } from './useFetchTemplateCollection'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const all = ({
  useQueryOptions = {},
}: {
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchTemplateCollection()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({ workspaceId: activeWorkspaceId as MaybeRef<string> }), // DEV-5806 remove casting
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ data }) => data,
    ...useQueryOptions,
  })
}

export const useQueryTemplateCollection = {
  all,
}
