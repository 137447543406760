import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import { responseDataTypeName, useFetchDocumentSummary } from './useFetchDocumentSummary'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byDocumentId: ({
    documentId,
    locale,
  }: {
    documentId: MaybeRef<string>
    locale: MaybeRef<string>
  }) => ({
    queryKey: [
      documentId,
      locale,
    ],
  }),
})

const byDocumentId = ({
  documentId,
  useQueryOptions = {},
}: {
  documentId: MaybeRef<string>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getByDocumentId } = useFetchDocumentSummary()
  const { activeWorkspaceId } = useAuth()
  const { language } = useLanguage()

  return useQuery({
    ...queryKeys.byDocumentId({
      documentId,
      locale: language,
    }),
    queryFn: () => getByDocumentId({
      workspaceId: unref(activeWorkspaceId) ?? '',
      documentId: unref(documentId),
    }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ data }) => data,
    ...useQueryOptions,
  })
}

export const useQueryDocumentSummary = {
  byDocumentId,
}
