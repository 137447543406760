import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45app_45version_45global from "/vercel/path0/apps/app/middleware/0-app-version.global.ts";
import _1_45auth_45global from "/vercel/path0/apps/app/middleware/1-auth.global.ts";
import _2_45archive_45version_45global from "/vercel/path0/apps/app/middleware/2-archive-version.global.ts";
import _3_45anon_45access_45restriction_45global from "/vercel/path0/apps/app/middleware/3-anon-access-restriction.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45app_45version_45global,
  _1_45auth_45global,
  _2_45archive_45version_45global,
  _3_45anon_45access_45restriction_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}