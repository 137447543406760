export default defineNuxtRouteMiddleware(async (to) => {
  const { isAnonymousWorkspace } = useAuth()

  if (!isAnonymousWorkspace.value)
    return

  if (to.name === '2-template-create-type-id' || (to.name === '2-template-templateId' && to.query.create === 'true') || to.name === '2-subscription')
    return

  const { public: { appV1Url } } = useRuntimeConfig()

  await navigateTo(`${appV1Url}/signup`, { external: true })
})
