import { createQueryKeys } from '@lukemorales/query-key-factory'

import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

import {
  type ParametersGetAll,
  responseDataTypeName,
  useFetchSignInvitation,
} from './useFetchSignInvitation'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    params,
  }: {
    params?: MaybeRef<ParametersGetAll>
  }) => ({
    queryKey: [{ params }],
  }),
})

const all = ({
  params,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetAll>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchSignInvitation()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({ params }),
    queryFn: () => getAll({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    ...useQueryOptions,
  })
}

export const useQuerySignInvitation = {
  all,
}
