import { FetchError } from 'ofetch'

import type { CollectionResponseMeta } from '~/types/api/v2'
import type { Document, DocumentPayload, FilterData, Folder, FolderPayload } from '~/types'

const { workspace, activeWorkspaceId } = useAuth()

export const useDocumentsItemSelection = <T extends Document | Folder = Document | Folder>() => useState<T | undefined>()
export const useCurrentFolder = () => useState<Folder | undefined>()
export const useRootFolder = () => useState<Folder | undefined>()
export const useDocumentListPagination = () => useState<Record<'documents' | 'folders', CollectionResponseMeta | undefined>>(() => ({ documents: undefined, folders: undefined }))

const documentListItemInserted = createEventHook<Document | Folder>()
export const onDocumentListItemInserted = documentListItemInserted.on

const route = useRoute()

const loadingStateByRoutePath = ref<Record<string, boolean | undefined>>({})

export const documentsDataLoading = computed(() => {
  if (!(route.path === '/2/documents' || route.path.startsWith('/2/documents/f/')))
    return false

  return loadingStateByRoutePath.value?.[route.path] ?? false
})

export const rootFolderId = computed(() => workspace.value?.attributes.root_folder_id)

const documentListPagination = useDocumentListPagination()
const folderList = useFolderList()
const documentList = useDocumentList()

const { currentView } = useViews()

export const documentsSortStorageKey = computed(() => `${activeWorkspaceId.value}::folder::sort`)
export const documentsFiltersStorageKey = computed(
  () => `${activeWorkspaceId.value}::folder::filters`,
)
export const documentsFiltersIncludeSubfoldersStorageKey = computed(
  () => `${activeWorkspaceId.value}::folder::subfolders`,
)

const documentsSort = useSessionStorage(documentsSortStorageKey.value, DEFAULT_DOCUMENTS_SORT)
const documentsFilters = useSessionStorage<FilterData[]>(documentsFiltersStorageKey.value, [])
const documentsIncludeSubfolders = useSessionStorage<boolean>(documentsFiltersIncludeSubfoldersStorageKey.value, true)

watch(() => route.path, (p) => {
  if (p === '/2/documents' || p.startsWith('/2/documents/f/'))
    loadingStateByRoutePath.value[p] = true

  folderList.value = []
  documentList.value = []
  documentListPagination.value = { documents: undefined, folders: undefined }
})

export const documentsData = computed(
  () => [...(folderList.value ?? []), ...(documentList.value ?? [])],
)

export const canFetchMoreDocuments = computed(() => {
  if (!documentListPagination.value.documents)
    return false

  const { current_page, last_page } = documentListPagination.value.documents!
  return current_page < last_page
})

export const canFetchMoreFolders = computed(() => {
  if (!documentListPagination.value.folders)
    return false

  const { current_page, last_page } = documentListPagination.value.folders!
  return current_page < last_page
})

export const clearDocumentsData = () => {
  documentList.value = []
  folderList.value = []
  documentListPagination.value = { documents: undefined, folders: undefined }
}

export const documentsAbortController = ref<AbortController>()
watch(documentsAbortController, (controller) => {
  if (!controller)
    return

  controller.signal.onabort = () => {
    clearDocumentsData()
  }
})

const fetchFolders = async ({
  page,
  folderId,
  signal,
}: {
  page: number
  folderId: Maybe<string>
  signal: AbortSignal
}) => {
  if (!folderId)
    return

  // If subfolders are included in document list filtering page
  // no need to fetch folders
  if (!currentView.value && documentsFilters.value.length && documentsIncludeSubfolders.value) {
    folderList.value = []
    return
  }

  // only sort folders by name but take the order into account
  const sort = documentsSort.value.startsWith('-') ? '-name' : 'name'

  const { data, meta } = await useApi().fetchFolders(folderId)({ params: { sort, page }, signal })
  folderList.value = page === 1 ? data : [...folderList.value].concat(data)
  documentListPagination.value.folders = meta
}

const fetchDocuments = async ({
  page,
  folderId,
  signal,
}: {
  page: number
  folderId: Maybe<string>
  signal: AbortSignal
}) => {
  const params = {
    page,
    per_page: documentListPagination.value.documents?.per_page ?? DEFAULT_DOCUMENTS_PER_PAGE,
  }

  const filters = currentView.value?.filters
    ?? documentsFilters.value

  const sort = currentView.value?.sort
    ?? documentsSort.value

  const subfoldersAreIncluded = documentsIncludeSubfolders.value && filters.length

  const folderFilterPayload: App.Data.Payloads.Views.FilterPayload | null
    = folderId
      ? {
          clause: 'where_all',
          rules: [
            {
              entity: subfoldersAreIncluded ? 'document.folder.ancestor' : 'document',
              conditions: [{
                field: subfoldersAreIncluded ? 'id' : 'folder_id',
                operator: 'is_any_of',
                value: [folderId],
              }],
            },
          ],
        }
      : null

  const payload = {
    filters: transformFiltersToFiltersPayload(filters).concat(folderFilterPayload || []),
    sort,
  }

  const { data, meta } = await useApi().searchDocuments(payload, { params, signal })

  documentList.value = page === 1 ? data : [...documentList.value].concat(data)
  documentListPagination.value.documents = meta
}

export const fetchDocumentsData = async () => {
  if (documentsAbortController.value)
    documentsAbortController.value.abort()

  const path = route.path
  loadingStateByRoutePath.value[path] = true

  await nextTick()
  documentsAbortController.value = new AbortController()

  const folderId = currentView.value ? null : useCurrentFolder().value?.id || rootFolderId.value
  const abortSignal = documentsAbortController.value.signal
  let hasError = false

  try {
    await fetchFolders({ page: 1, folderId, signal: abortSignal })
    if (!canFetchMoreFolders.value)
      await fetchDocuments({ page: 1, folderId, signal: abortSignal })
  }
  catch (e) {
    hasError = true
    if (!(e instanceof FetchError) || (e?.cause as any)?.name !== 'AbortError')
      console.error(e)
  }
  finally {
    loadingStateByRoutePath.value[path] = false
  }

  return {
    hasError,
  }
}

watch(documentsDataInvalidationTicker, () => {
  fetchDocumentsData()
})

export const loadingMoreDocumentsData = ref(false)
export const fetchMoreDocumentsData = async () => {
  if (loadingMoreDocumentsData.value || (!canFetchMoreDocuments.value && !canFetchMoreFolders.value))
    return

  loadingMoreDocumentsData.value = true

  const folderId = currentView.value
    ? null
    : useCurrentFolder().value?.id || rootFolderId.value

  documentsAbortController.value = new AbortController()
  const signal = documentsAbortController.value.signal

  try {
    await fetchFolders({ page: (documentListPagination.value.folders?.current_page ?? 0) + 1, folderId, signal })
    if (!canFetchMoreFolders.value)
      await fetchDocuments({ page: (documentListPagination.value.documents?.current_page ?? 0) + 1, folderId, signal })
  }
  catch (e) {
    if (!(e instanceof FetchError) || (e?.cause as any)?.name !== 'AbortError')
      console.error(e)
  }
  finally {
    loadingMoreDocumentsData.value = false
  }
}

export const loadAllDocuments = async () => {
  let hardLimit = 100
  do {
    hardLimit--
    await fetchMoreDocumentsData()
  } while ((canFetchMoreDocuments.value || canFetchMoreFolders.value) && hardLimit >= 0)
}

export const updateFolder = async (folder: FolderPayload & { parent_id?: string }) => {
  const updated = await useApi().updateFolder(folder)().then(r => r.data)
  const currentFolderId = useCurrentFolder().value?.id || rootFolderId.value

  /**
   * Delete the folder if it is moved out of current folder
   */
  if (!currentView.value && currentFolderId
    && folder.parent_id && currentFolderId !== folder.parent_id) {
    folderList.value = [...(folderList.value ?? [])].filter(f => f.id !== updated.id)
  }
  else {
    folderList.value = [...(folderList.value ?? [])].map((f) => {
      if (f.id === updated.id)
        return updated

      return f
    })
  }

  const current = useCurrentFolder()
  if (updated.id === current.value?.id)
    current.value = updated

  return updated
}

/**
 * Replace the document in the documents list with the new document.
 */
export const replaceDocument = (document: Document) => {
  if (!documentList.value)
    return

  documentList.value = documentList.value!.map((d) => {
    if (d.id === document.id)
      return document

    return d
  })
}

export const updateDocument = async (document: DocumentPayload) => {
  const currentFolderId = useCurrentFolder().value?.id || rootFolderId.value
  const updated = await useApi().updateDocument(document)().then(r => r.data)

  /**
   * Remove document from list if it is moved out of current folder
   */
  if (!currentView.value && currentFolderId
    && document.folder_id && currentFolderId !== document.folder_id) {
    documentList.value = [...(documentList.value ?? [])].filter(d => d.id !== updated.id)
  }

  else {
    replaceDocument(updated)
  }

  const currenctSelection = useDocumentsItemSelection().value
  const currentSelectionId
    = currenctSelection && currenctSelection?._type === 'document' && currenctSelection.id

  if (
    currentSelectionId
    && currentSelectionId === document.id
    && documentList.value.length
    && !documentList.value.some(({ id }) => id === document.id)
  ) {
    useDocumentsItemSelection().value = undefined
  }

  return updated
}

export const createFolder = async (folder: { parent_id: string, name: string }) => {
  const created = await useApi().createFolder(folder).then(r => r.data)
  const currentFolderId = useCurrentFolder().value?.id

  if (currentFolderId === folder.parent_id || (!currentFolderId && folder.parent_id === rootFolderId.value)) {
    folderList.value?.push(created)
    documentListItemInserted.trigger(created)
  }

  return created
}

const isDocumentPreviewPage = computed(() => route.name === '2-documents-d-documentId')

export const onFolderDeleted = (folder: Folder) => {
  const { selection: multiselection } = useDocumentsMultiSelection()
  folderList.value = folderList.value?.filter(f => f.id !== folder.id)

  const activeItem = useDocumentsItemSelection()
  if (activeItem.value?.id === folder.id)
    activeItem.value = useCurrentFolder().value

  if (multiselection.value.some(x => x._type === 'folder' && x.id === folder.id))
    multiselection.value = multiselection.value?.filter(x => x.id !== folder.id)

  const currentFolder = useCurrentFolder()
  if (currentFolder.value?.id === folder.id && folder.parent_id) {
    navigateTo(
      folder.parent_id === rootFolderId.value
        ? '/2/documents'
        : `/2/documents/f/${folder.parent_id}`,
      { replace: true },
    )
    currentFolder.value = folder.parent ?? undefined
  }
}

export const deleteFolder = async (folder: Folder) => {
  await useApi().deleteFolder(folder.id)
  onFolderDeleted(folder)
}

export const onDocumentDeleted = (documentId: string) => {
  const { selection: multiselection } = useDocumentsMultiSelection()
  documentList.value = documentList.value?.filter(d => d.id !== documentId)

  const activeItem = useDocumentsItemSelection()
  if (activeItem.value?.id === documentId)
    activeItem.value = useCurrentFolder().value

  if (multiselection.value.some(x => x._type === 'document' && x.id === documentId))
    multiselection.value = multiselection.value?.filter(x => x.id !== documentId)

  if (isDocumentPreviewPage.value && route.params.documentId === documentId)
    navigateTo('/2/documents')
}

export const deleteDocument = async (documentId: string) => {
  await useApi().deleteDocument(documentId)
  onDocumentDeleted(documentId)
}
