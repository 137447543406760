import type { ISO8601Date } from '~/types'
import type { Model, Response } from '~/types/api/v1'

import { populateRelationships } from '~/utils/api/v1/relationships'

import type { ContractStage } from '../contract/types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName = 'sign-invitation'

export interface ParametersGetAll {
  limit?: number
}

interface PartyData {
  firstName: string
  lastName: string
  companyTitle: string
  signedAt: Maybe<ISO8601Date>
}

export type SignInvitationData = Model<{
  type: 'sign-invitation'
  createdAt: ISO8601Date
}, {
    contract: { data: Model<{
      name: string
      createdAt: Maybe<ISO8601Date>
      updatedAt: Maybe<ISO8601Date>
      stage: ContractStage
      creatorName: Maybe<string>
      parties: PartyData[]
    }> }
    permission: { data: Model<{
      pin: string
      signingUrl: string
    }> }
  }>

export const useFetchSignInvitation = () => {
  const { get } = useFetchWithDefaults()

  const getAll = (
    {
      params,
      workspaceId,
    }: {
      params?: ParametersGetAll
      workspaceId: string
    }) => {
    const url = '/me/sign-invitations?include=contract,permission&signatureState=waiting'

    return get<Response<SignInvitationData[]>>({
      url,
      workspaceId,
      fetchOptions: { params },
    }).then(populateRelationships)
  }

  return {
    getAll,
  }
}
