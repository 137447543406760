<script lang="ts" setup>
import LinePlus from '@docue/docue-ui-v2/icons/LinePlus.vue'
import LineUser01 from '@docue/docue-ui-v2/icons/LineUser01.vue'
import LineLogOut01 from '@docue/docue-ui-v2/icons/LineLogOut01.vue'
import LineSettings02 from '@docue/docue-ui-v2/icons/LineSettings02.vue'
import ChooseWorkspaceModal from '~~/components/ChooseWorkspaceModal.vue'
import LineChevronDown from '@docue/docue-ui-v2/icons/LineChevronDown.vue'
import SolidCheckCircle from '@docue/docue-ui-v2/icons/SolidCheckCircle.vue'
import LineMessageDotsCircle from '@docue/docue-ui-v2/icons/LineMessageDotsCircle.vue'
import LineSwitchHorizontal01 from '@docue/docue-ui-v2/icons/LineSwitchHorizontal01.vue'

const {
  user,
  workspace,
} = useAuth()

const {
  isMainSubscriptionNonRenewing,
  hasPaidSubscription,
  mainSubscription,
  hasValidSubscription,
} = useSubscription()

const workspaceModal = ref<InstanceType<typeof ChooseWorkspaceModal>>()

const { supportPortal } = useLinks()
const {
  signatureBalance,
  freeContractBalance,
  hasUnlimitedFreeContracts,
  signatureSubscriptionBalance,
} = useBalances()

const totalSignatureBalance = computed(() =>
  signatureBalance.value === -1 || signatureSubscriptionBalance.value === -1
    ? Number.POSITIVE_INFINITY
    : signatureBalance.value + signatureSubscriptionBalance.value,
)

const isSubscribeModalOpen = useSubscriptionModalOpenState()

const subscribeButtonAttributes = computed(() =>
  !hasValidSubscription.value
    ? {
        onClick: () => {
          isSubscribeModalOpen.value = true
        },
      }
    : { to: `/subscribe?return=${window.location.toString()}`, external: true },
)
</script>

<template>
  <ChooseWorkspaceModal ref="workspaceModal" />
  <DTDropdown #default="{ close }">
    <DTDropdownButton
      variant="plain"
      class="!-ml-1.5 border-0 !p-1.5"
      wrapper-class="[&>button]:rounded-lg"
    >
      <div
        class="
            flex
            items-center
            justify-center
            gap-2.5
            rounded-lg
            text-left
            sm:min-w-[210px]
            "
      >
        <DTAvatar
          size="sm"
          variant="colored"
          :name="workspace?.attributes?.displayName ?? user!.attributes.name"
          :src="workspace?.meta?.avatar?.url"
          :alt="workspace?.meta?.avatar?.name"
        />
        <div class="w-full">
          <p
            class="line-clamp-1 max-w-sm text-sm font-medium"
            :title="workspace!.attributes.displayName ?? ''"
          >
            {{ workspace!.attributes.displayName }}
          </p>
          <p
            class="line-clamp-1 text-3xs font-normal leading-[15px] text-gray-400"
            :title="user!.attributes.name"
          >
            {{ user!.attributes.name }}
          </p>
        </div>
        <div class="flex items-center justify-center">
          <span
            class="flex size-7 items-center justify-center rounded-full"
          >
            <LineChevronDown class="mt-0.5 size-4 text-gray-600" />
          </span>
        </div>
      </div>
    </DTDropdownButton>

    <DTDropdownMenu
      placement="bottom-start"
      :offset="2"
      class="mb-1 w-[calc(100vw-2rem)] !rounded-xl sm:w-max sm:min-w-[268px] [&>div]:!px-0"
    >
      <div class="flex flex-col divide-y !divide-gray-200">
        <div class="flex flex-col gap-2 px-4.5 pb-3 pt-1.5">
          <div class="flex items-center justify-between gap-5">
            <div class="flex items-center gap-2">
              <span class="flex h-6 items-center">
                <SolidCheckCircle
                  v-if="hasPaidSubscription"
                  class="size-4"
                  :class="isMainSubscriptionNonRenewing ? 'text-yellow-500' : 'text-green-500'"
                />
                <LineUser01
                  v-else
                  class="size-4"
                />
              </span>
              <span class="line-clamp-1 max-w-[200px] break-all text-sm">
                {{
                  hasPaidSubscription
                    ? mainSubscription?.plan_name
                    : workspace?.attributes.displayName
                }}
              </span>
            </div>
            <DTButton
              v-bind="subscribeButtonAttributes"
              variant="link"
              dense
              size="xs"
              @click="close"
            >
              {{
                hasPaidSubscription
                  ? $t('user-menu.manage-subscription')
                  : $t('user-menu.activate-subscription')
              }}
            </DTButton>
          </div>
          <div class="flex flex-col gap-1.5 pl-[22px]">
            <div
              v-if="
                !hasPaidSubscription || (
                  totalSignatureBalance > 0 /* a balance of zero is not shown because of post-paid plans */
                  && Number.isFinite(totalSignatureBalance)
                )
              "
              class="flex items-center justify-between gap-1.5"
            >
              <span class="text-xs text-gray-600">
                {{ $t('user-menu.signatures-remaining') }}
              </span>
              <span
                class="
                    rounded-md
                    border
                    border-gray-200
                    bg-gray-100
                    px-0.5
                    text-3xs
                    text-gray-600
                    "
              >
                {{ Number.isFinite(totalSignatureBalance) ? totalSignatureBalance : $t('unlimited') }}
              </span>
            </div>
            <div class="flex items-center justify-between gap-1.5">
              <span class="text-xs text-gray-600">
                {{ $t('user-menu.documents-remaining') }}
              </span>
              <span
                class="
                    rounded-md
                    border
                    border-gray-200
                    bg-gray-100
                    px-0.5
                    text-3xs
                    text-gray-600
                    "
              >{{ hasUnlimitedFreeContracts ? $t('unlimited') : freeContractBalance }}</span>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-0.5 p-1.5">
          <DTDropdownMenuItem to="/settings">
            <LineSettings02 class="size-4 shrink-0 text-gray-600" />
            {{ $t('user-menu.link.settings') }}
          </DTDropdownMenuItem>

          <DTDropdownMenuItem @click="workspaceModal!.open() && close()">
            <LineSwitchHorizontal01 class="size-4 shrink-0 text-gray-600" />
            {{ $t('user-menu.link.switch-workspaces') }}
          </DTDropdownMenuItem>

          <DTDropdownMenuItem to="/signup/create-workspace?allowBack=true">
            <LinePlus class="size-4 shrink-0 text-gray-600" />
            {{ $t('user-menu.link.new-workspace') }}
          </DTDropdownMenuItem>

          <DTDropdownMenuItem :to="supportPortal">
            <LineMessageDotsCircle class="size-4 shrink-0 text-gray-600" />
            {{ $t('user-menu.link.support') }}
          </DTDropdownMenuItem>
        </div>

        <div class="flex flex-col p-1.5">
          <span class="p-1.5 pl-2.5 text-xs text-gray-400">
            {{ user!.attributes.email }}
          </span>
          <DTDropdownMenuItem to="/2/logout">
            <LineLogOut01 class="w-4 shrink-0 text-red-500" />
            <span class="text-red-500">{{ $t('auth.log-out') }}</span>
          </DTDropdownMenuItem>
        </div>
      </div>
    </DTDropdownMenu>
  </DTDropdown>
</template>
