import type { CollectionResponse, IncludedFieldDependentResponse, Response } from '~/types/api/v2'

import { useFetchWithDefaults } from '~/composables/api/useFetchWithDefaults'

import {
  type DocumentIdentifier,
  type DocumentMutationParams,
  getUrlById as getDocumentByIdUrl,
} from '../useFetchDocument'

type ReminderData = App.Data.Document.Reminders.ReminderData
export const responseDataTypeName: ReminderData['_type'] = 'reminder'

export type IncludableField = 'schedules' | 'recipients'

export const useFetchDocumentReminder = () => {
  const getUrl = (documentId: string) => `${getDocumentByIdUrl(documentId)}/reminders`

  const { get, put, post, deleteRequest } = useFetchWithDefaults()

  type CreateReminderPayload = App.Data.Payloads.Documents.Reminders.CreateReminderPayload
  type UpdateReminderPayload = App.Data.Payloads.Documents.Reminders.UpdateReminderPayload

  type DynamicResponse<TFieldsToInclude extends string[]> =
    IncludedFieldDependentResponse<
      ReminderData,
      TFieldsToInclude,
      IncludableField
    >

  const byDocumentId = <TFieldsToInclude extends IncludableField[] = []>({
    documentId,
    workspaceId,
    fieldsToInclude,
  }: DocumentIdentifier & { fieldsToInclude: TFieldsToInclude }) =>
    get<CollectionResponse<DynamicResponse<TFieldsToInclude>>>({
      url: getUrl(documentId),
      workspaceId,
      fetchOptions: {
        params: { include: fieldsToInclude ?? [] },
      },
    })

  const create = <TFieldsToInclude extends IncludableField[] = []>(
    {
      payload,
      documentId,
      workspaceId,
      fieldsToInclude,
    }: DocumentMutationParams<CreateReminderPayload> & { fieldsToInclude: TFieldsToInclude }) =>
    post<Response<DynamicResponse<TFieldsToInclude>>>({
      url: getUrl(documentId),
      workspaceId,
      fetchOptions: {
        body: payload,
        params: { include: fieldsToInclude ?? [] },
      },
    })

  const update = <TFieldsToInclude extends IncludableField[] = []>(
    {
      payload,
      reminderId,
      documentId,
      workspaceId,
      fieldsToInclude,
    }: DocumentMutationParams<UpdateReminderPayload> & {
      reminderId: string
      fieldsToInclude: TFieldsToInclude
    }) =>
    put<Response<DynamicResponse<TFieldsToInclude>>>({
      url: `${getUrl(documentId)}/${reminderId}`,
      workspaceId,
      fetchOptions: {
        body: payload,
        params: { include: fieldsToInclude ?? [] },
      },
    })

  const drop = ({
    workspaceId,
    documentId,
    reminderId,
  }:
   DocumentIdentifier & { reminderId: string }) => deleteRequest<void>({
    url: `${getUrl(documentId)}/${reminderId}`,
    workspaceId,
  })

  return {
    drop,
    create,
    update,
    byDocumentId,
  }
}
