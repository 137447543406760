import i18nConfig from '~~/config/vue-i18n'

const DEFAULT_LANGUAGE = 'en'
const availableLocales = i18nConfig.availableLocales as App.Internals.Enums.Language[]

export default createSharedComposable(() => {
  const { enabled: isPolishLanguageEnabled } = useFeatureEnabled('polish-language')
  const languages = computed(() => isPolishLanguageEnabled.value ? availableLocales : availableLocales.filter(l => l !== 'pl'))

  const browserLanguage = languages.value.find(
    l => usePreferredLanguages().value.find(pl => pl.includes(l)),
  )
  const queryLanguage = languages.value.find(
    l => (new URLSearchParams(location.search)).get('language') === l,
  )
  const cookieLanguage = languages.value.find(l => useCookie('docue_country').value?.startsWith(l))
  const storageLanguage = languages.value.find(l => useLocalStorage('locale', null).value === l)

  const language = useState<App.Internals.Enums.Language>(
    'language',
    () => storageLanguage || queryLanguage || cookieLanguage || browserLanguage || DEFAULT_LANGUAGE,
  )

  const { setLocale } = useNuxtApp().$i18n
  watch(language, (l) => {
    setLocale(l)
    useLocalStorage('locale', l).value = l
  }, { immediate: true })

  return { language, languages }
})
