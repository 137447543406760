import type { AnyTemplate } from '~/types'
import type { WorkspaceIdentifier } from '~/composables/api/types'
import type { Model, Relationship, Response } from '~/types/api/v1'

import { populateRelationships } from '~/utils/api/v1'

import type { TemplateData } from '../useFetchTemplate'
import type { UserTemplateData } from '../user-template/useFetchUserTemplate'

import { useFetchWithDefaults } from '../../useFetchWithDefaults'

export const responseDataTypeName = 'favourite'

export type FavouriteData = Model<object, {
  template?: Relationship<TemplateData | UserTemplateData>
}> & {
  type: 'favourite'
}

export interface ParametersGetAll {
  limit?: number
}

export const useFetchFavourite = () => {
  const { get, deleteRequest, post } = useFetchWithDefaults()
  const url = '/me/favourites'

  const getAll = ({
    workspaceId,
    params,
  }: {
    workspaceId: string
    params?: ParametersGetAll
  }) =>
    get<Response<FavouriteData[]>>({
      url,
      workspaceId,
      fetchOptions: { params: {
        ...params,
        include: ['template'],
      } },
    }).then(populateRelationships)

  const add = ({ template, workspaceId }: { template: AnyTemplate } & WorkspaceIdentifier) => {
    const type = getTemplateType(template)
    return post<Response<FavouriteData>>({
      url,
      workspaceId,
      fetchOptions: {
        body: { data: { templateId: template.id, templateType: type === 'user_template' ? 'userTemplate' : type } },
        params: { include: ['template'] },
      },
    })
  }

  const remove = ({ id, workspaceId }: { id: string } & WorkspaceIdentifier) => deleteRequest({
    url: `${url}/${id}`,
    workspaceId,
  })

  return {
    getAll,
    add,
    remove,
  }
}
