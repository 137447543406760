
// @ts-nocheck
import locale__vercel_path0_apps_app_locales_en_json from "../locales/en.json";
import locale__vercel_path0_apps_app_locales_fi_json from "../locales/fi.json";
import locale__vercel_path0_apps_app_locales_sv_json from "../locales/sv.json";
import locale__vercel_path0_apps_app_locales_de_json from "../locales/de.json";
import locale__vercel_path0_apps_app_locales_pl_json from "../locales/pl.json";


export const localeCodes =  [
  "en",
  "fi",
  "sv",
  "de",
  "pl"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale__vercel_path0_apps_app_locales_en_json), cache: true }],
  "fi": [{ key: "../locales/fi.json", load: () => Promise.resolve(locale__vercel_path0_apps_app_locales_fi_json), cache: true }],
  "sv": [{ key: "../locales/sv.json", load: () => Promise.resolve(locale__vercel_path0_apps_app_locales_sv_json), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => Promise.resolve(locale__vercel_path0_apps_app_locales_de_json), cache: true }],
  "pl": [{ key: "../locales/pl.json", load: () => Promise.resolve(locale__vercel_path0_apps_app_locales_pl_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/vue-i18n.ts?hash=c617df9b&config=1" /* webpackChunkName: "__config_vue_i18n_c617df9b" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/vue-i18n",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "/vercel/path0/apps/app/locales/en.json"
      ]
    },
    {
      "code": "fi",
      "name": "Finnish",
      "files": [
        "/vercel/path0/apps/app/locales/fi.json"
      ]
    },
    {
      "code": "sv",
      "name": "Swedish",
      "files": [
        "/vercel/path0/apps/app/locales/sv.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "files": [
        "/vercel/path0/apps/app/locales/de.json"
      ]
    },
    {
      "code": "pl",
      "name": "Polish",
      "files": [
        "/vercel/path0/apps/app/locales/pl.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/vercel/path0/apps/app/locales/en.json"
      }
    ]
  },
  {
    "code": "fi",
    "name": "Finnish",
    "files": [
      {
        "path": "/vercel/path0/apps/app/locales/fi.json"
      }
    ]
  },
  {
    "code": "sv",
    "name": "Swedish",
    "files": [
      {
        "path": "/vercel/path0/apps/app/locales/sv.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "files": [
      {
        "path": "/vercel/path0/apps/app/locales/de.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polish",
    "files": [
      {
        "path": "/vercel/path0/apps/app/locales/pl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
