import type { Features } from '~/types/auth'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const useFetchFeature = () => {
  const url = '/me/features'

  const { get } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<{ data: Features }>({ url, workspaceId })

  return {
    getAll,
  }
}
