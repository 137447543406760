/**
 * !Note: Make sure legacy plugin is loaded after this plugin as it has dependency on this plugin
 */

import posthog from 'posthog-js'

type Posthog = typeof posthog & {
  enable: () => void
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { cookies } = useCookieConsent()

  if (config.public.posthogToken) {
    posthog.init(config.public.posthogToken, {
      api_host: 'https://eu.posthog.com',
      disable_session_recording: true,
      debug: config.public.stage === 'development',
      persistence: cookies.value?.analytics ? 'cookie' : 'memory',
      person_profiles: 'always',
      session_recording: {
        maskTextSelector: '*',
      },
    })
  }

  (posthog as Posthog).enable = function () {
    posthog.set_config({ persistence: 'cookie' })
  }

  /**
   * Setup listeners
   */
  const { user, workspace } = useAuth()
  watch(user, (user, oldUser) => {
    if (user)
      posthog.identify(user.id, { email: user.attributes.email, app_v2_version: config.public.appVersion })
    else if (!user && oldUser) // reset only when user changes from user to not user, not on initial load
      posthog.reset()
  }, { immediate: true })

  watch(workspace, (workspace) => {
    if (!workspace)
      return

    posthog.setPersonProperties({
      cw_id: workspace.id,
      cw_name: workspace.attributes.displayName,
      cw_country: workspace.attributes.country,
      cw_created_at: workspace.attributes.createdAt,
      cw_type: workspace.attributes.workspaceType,
      cw_subscription_status: workspace.attributes.subscription_status,
      cw_subscription_plan: workspace.attributes.subscription_plan,
      cw_source: workspace.attributes.source,
    })

    posthog.group('workspace', workspace.id, {
      name: workspace.attributes.displayName,
      created_at: workspace.attributes.createdAt,
      country: workspace.attributes.country,
      type: workspace.attributes.workspaceType,
      subscription_status: workspace.attributes.subscription_status,
      subscription_plan: workspace.attributes.subscription_plan,
    })
  }, { immediate: true })

  const router = useRouter()
  router.afterEach(() => {
    posthog.capture('$pageview')
  })

  return {
    provide: {
      posthog: posthog as Posthog,
    },
  }
})
