import type { Preference } from '~/types/auth'
import type { Response } from '~/types/api/v1'

import type { WorkspaceIdentifier } from '../types'

import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName = 'preference'

export interface PreferenceUpsertPayload extends Omit<Preference['attributes'], 'updatedAt' | 'createdAt'> {}

export const useFetchPreferences = () => {
  const getUrl = (key: string) => `/me/preferences/${key}`

  const { get, post, deleteRequest } = useFetchWithDefaults()

  const getByKey = ({
    workspaceId,
    key,
  }: WorkspaceIdentifier & { key: string }) => get<Response<Preference>>({ url: getUrl(key), workspaceId })

  const upsertByKey = ({ workspaceId, payload }: WorkspaceIdentifier & { payload: PreferenceUpsertPayload }) =>
    post<Response<Preference>>({ url: getUrl(payload.key), workspaceId, fetchOptions: { body: { data: { attributes: { value: payload.value } } } } })

  const dropByKey = ({ workspaceId, key }: WorkspaceIdentifier & { key: string }) =>
    deleteRequest({ url: getUrl(key), workspaceId })

  return {
    getByKey,
    upsertByKey,
    dropByKey,
  }
}
