import revive_payload_client_A3WlzN4mug from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_U3UlH4tZIM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IHCUngmzJf from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MxW5YaLvH0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XeZY97jpZc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xNyLcZ7E5w from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GYL99UZepw from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_wFYYdmYay1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_omtqAzY7fd from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.22.5_vite@5.4.8_@types+node@20.17.9_sass@1.79.4_ter_65ockktqytrbdqssipg35wi22e/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_oRdNlzmhcj from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_uBtSEc6esz from "/vercel/path0/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.2.1_@tanstack+vue-query@5.56.2_vue@3.5.12_typescript@5.6.3___nu_ncx4rwnfslzg2ws3ey6abkeloa/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_YxRcO84sMh from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.22.5_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_5MfVKlP8dM from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.22.5_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import tooltip_X0Ourw2CUb from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.2_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._bam3w57nqaj7gqlbqoe5kcg4eq/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import _01_posthog_client_njXv1AyeXs from "/vercel/path0/apps/app/plugins/01.posthog.client.ts";
import _02_legacy_client_v1XKct5FiP from "/vercel/path0/apps/app/plugins/02.legacy.client.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/app/plugins/bugsnag.ts";
import gtm_client_OzmBuHLRIb from "/vercel/path0/apps/app/plugins/gtm.client.ts";
import intercom_client_vr7NPky0pK from "/vercel/path0/apps/app/plugins/intercom.client.ts";
export default [
  revive_payload_client_A3WlzN4mug,
  unhead_U3UlH4tZIM,
  router_IHCUngmzJf,
  payload_client_MxW5YaLvH0,
  navigation_repaint_client_XeZY97jpZc,
  check_outdated_build_client_xNyLcZ7E5w,
  chunk_reload_client_GYL99UZepw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wFYYdmYay1,
  plugin_omtqAzY7fd,
  plugin_oRdNlzmhcj,
  plugin_uBtSEc6esz,
  plugin_ywJDomcNte,
  switch_locale_path_ssr_YxRcO84sMh,
  i18n_5MfVKlP8dM,
  tooltip_X0Ourw2CUb,
  _01_posthog_client_njXv1AyeXs,
  _02_legacy_client_v1XKct5FiP,
  bugsnag_tkMQfOjcVZ,
  gtm_client_OzmBuHLRIb,
  intercom_client_vr7NPky0pK
]