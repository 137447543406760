/**
 * This plugin ensures the transition to app v1 pages is smooth and stores some values in session storage.
 * !NOTE: This plugin should be loaded after the posthog plugin as this contains dependencies that depend on it.
 */

export default defineNuxtPlugin(() => {
  // The following check is required due to the use of a child Nuxt app embedded within the app via an iframe.
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('is-child-app') === 'true')
    return

  const { activeWorkspaceId, workspace } = useAuth()
  const areCachingQueriesEnabled = computed(() => !!workspace.value)

  const toCache = [{
    useQueryFn: useQueryBalance.all,
    key: 'record_balance',
  }, {
    useQueryFn: useQueryConfig.current,
    key: 'config',
  }]

  toCache.forEach(({ useQueryFn, key }) => {
    const { data, isFetched } = useQueryFn({
      useQueryOptions: {
        enabled: areCachingQueriesEnabled,
      },
    })

    const appV1Representation = computed(() => ({ value: data.value ?? null }))

    watch(appV1Representation, () => {
      if (activeWorkspaceId.value && isFetched.value) {
        sessionStorage.setItem(
          `${activeWorkspaceId.value}-${key}`,
          JSON.stringify(appV1Representation.value),
        )
      }
    })
  })
})
